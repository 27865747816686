import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SurveyService } from '../../services/survey.service';
declare var $, swal: any, Bloodhound: any;

@Component({
  selector: 'app-survey7',
  templateUrl: './survey7.component.html',
  styleUrls: ['./survey7.component.css']
})
export class Survey7Component implements OnInit {

  userEntity: any = {};
  submitted: boolean = false;
  questionEntity: any = {};
  slider1: boolean = false;
  slider2: boolean = true;
  slider3: boolean = false;
  checkDisabled: boolean = true;
  showAdditionalFeedback: boolean = false;
  userEntityDisable: boolean = false;
  countryList;
  usStateList;
  surveyDetails;
  id;
  totalQuestions: number = 8;
  questionList: any = [
    { 'QuestionId': 104, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 105, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 106, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 107, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 108, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 109, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 110, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 111, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null }
  ];

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute,
    private SurveyService: SurveyService) { }

  ngOnInit() {
    $(window).scrollTop(0);
    setTimeout(() => {
      const body = document.querySelector('body');
      body.style.setProperty('--screen-height', $(window).height() + "px");
    }, 100);

    $('#progress_circle').circleProgress({
      value: 0,
      size: 100.0,
      emptyFill: '#ccc',
      fill: { gradient: ['#033F6F', '#033F6F'] }
    }).on('circle-animation-progress', function (event, progress) {
      $(this).find('strong').html('0%');
    });

    this.userEntity.UserId = 0;
    this.userEntity.SurveyUserId = this.route.snapshot.paramMap.get('suid');
    this.userEntity.SurveyId = 7;
    this.userEntity.IsAnonymous = true;

    this.id = this.route.snapshot.url[2].path;
    this.SurveyService.getSurveyDetails(this.id)
      .then((data) => {
        this.surveyDetails = data;
        if (new Date(this.surveyDetails.ExpiryDate) >= new Date(this.globals.usaTime)) {
          this.globals.title.setTitle('Survey for Year in Review – Reflections and Lessons Learned by PopTopiks');
          this.globals.logo = data['Logo'];
          this.globals.logoURL = data['LogoURL'];
        } else {
          this.router.navigate(['/expired']);
        }
        this.globals.isLoading = false;
      },
        (error) => {
          this.globals.isLoading = false;
          this.router.navigate(['/pagenotfound']);
        });
  }

  /* getState(country) {
    if (country == 'United States of America') {
      this.showUSStates = true;
    } else {
      this.showUSStates = false;
      this.questionEntity.usstate = 'Select State';
      this.questionList[6].AnswerText = '';
    }
    if (country == 'Select Country') {
      country = '';
      this.questionEntity.usstate = 'Select State';
      this.questionList[6].AnswerText = '';
    }
    this.submitAnswer(5, country, null);
  }

  changeState(state) {
    if (state == 'Select State') {
      state = '';
    }
    this.submitAnswer(6, state, null);
  } */

  AnonymousChange() {
    if (!this.userEntity.IsAnonymous) {
      this.userEntityDisable = true;
      this.userEntity.FirstName = null;
      this.userEntity.LastName = null;
      this.userEntity.EmailAddress = null;
      this.userEntity.PhoneNumber = null;
      this.userEntity.Company = null;
    } else {
      this.userEntityDisable = false;
    }
  }

  startSurvey(userForm) {
    // this.slider1 = false;
    // this.slider2 = true;

    this.submitted = true;
    if (userForm.valid) {
      this.globals.isLoading = true;
      this.SurveyService.startSurvey(this.userEntity)
        .then((data) => {
          this.userEntity.UserId = data['UserId'];
          this.userEntity.SurveyUserId = data['SurveyUserId'];
          this.questionEntity.SurveyUserId = data['SurveyUserId'];
          this.globals.isLoading = false;
          this.slider1 = false;
          this.slider2 = true;
          this.submitted = false;
          //console.log(this.questionEntity);
        },
          (error) => {
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
  }

  submitSurveywithUser(userForm) {
    debugger
    this.submitted = true;
    if (userForm.valid) {
      $('#Preview_Modal').modal('hide');
      this.globals.isLoading = true;
      let postData = this.userEntity;
      postData.IsAnonymous = false;
      postData.SurveyStatus = this.userEntity.SurveyStatus;
      this.SurveyService.submitSurveywithUser(postData)
        .then((data) => {
          this.globals.isLoading = false;
          this.router.navigate(['/thankyou']);
        },
          (error) => {
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
  }

  submitSurvey(SurveyStatus) {
    this.userEntity.SurveyStatus = SurveyStatus;
    this.globals.surveyId = 7;
    if (this.userEntity.IsAnonymous) {
      swal({
        type: this.globals.commonTranslationText.common.alerts.confirmSubmit.type,
        title: this.globals.commonTranslationText.common.alerts.confirmSubmit.title,
        text: this.globals.commonTranslationText.common.alerts.confirmSubmit.text,
        //icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: "No"
      })
        .then((result) => {
          if (result.value) {
            this.globals.isLoading = true;
            let postData = { 'SurveyUserId': this.userEntity.SurveyUserId, 'SurveyStatus': SurveyStatus, 'IsAnonymous': this.userEntity.IsAnonymous, 'EmailAddress': this.userEntity.EmailAddress, 'SurveyId': this.userEntity.SurveyId, 'UserId': this.userEntity.UserId };
            this.SurveyService.submitSurvey(postData)
              .then((data) => {
                this.globals.isLoading = false;
                this.router.navigate(['/thankyou']);
              },
                (error) => {
                  this.globals.isLoading = false;
                  swal({
                    type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                    title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                    text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                    showConfirmButton: false,
                    timer: 4000
                  })
                });
          } else {
            $('#Preview_Modal').modal('show');
          }
        })
    } else {
      swal({
        type: this.globals.commonTranslationText.common.alerts.confirmSubmit.type,
        title: this.globals.commonTranslationText.common.alerts.confirmSubmit.title,
        text: this.globals.commonTranslationText.common.alerts.confirmSubmit.text,
        //icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: "No"
      })
        .then((result) => {
          if (result.value) {
            this.globals.isLoading = true;
            let postData = { 'SurveyUserId': this.userEntity.SurveyUserId, 'SurveyStatus': SurveyStatus, 'IsAnonymous': this.userEntity.IsAnonymous, 'EmailAddress': this.userEntity.EmailAddress, 'SurveyId': this.userEntity.SurveyId, 'UserId': this.userEntity.UserId };
            this.SurveyService.submitSurvey(postData)
              .then((data) => {
                this.globals.isLoading = false;
                this.router.navigate(['/thankyou']);
              },
                (error) => {
                  this.globals.isLoading = false;
                  swal({
                    type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                    title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                    text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                    showConfirmButton: false,
                    timer: 4000
                  })
                });
          }
        })
    }
  }

  checkAdditionalFeedback(QuestionId, AnswerText, Comment) {
    if (AnswerText == 'Yes') {
      this.showAdditionalFeedback = true;
    } else {
      this.showAdditionalFeedback = false;
    }
    this.submitAnswer(QuestionId, AnswerText, Comment);
  }

  toggleChange(QuestionId, AnswerText, Comment) {
    if (AnswerText) {
      AnswerText = 'Yes';
    } else {
      AnswerText = 'No';
    }
    this.submitAnswer(QuestionId, AnswerText, Comment);
  }

  submitAnswer(QuestionId, AnswerText, Comment) {
    //this.globals.isLoading = true;
    var index = this.questionList.findIndex(x => x.QuestionId === QuestionId);
    let postData = {
      'SurveyUserId': this.userEntity.SurveyUserId,
      'SurveyAnswerId': this.questionList[index].SurveyAnswerId,
      'QuestionId': QuestionId,
      'AnswerText': AnswerText, 'Comment': Comment
    };

    this.SurveyService.submitAnswer(postData)
      .then((data) => {
        this.questionList[index].SurveyAnswerId = data['SurveyAnswerId'];
        this.questionList[index].AnswerText = $.trim(AnswerText);
        this.questionList[index].Comment = Comment;
        this.checkprogress();
        //this.globals.isLoading = false;
      },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000
          })
        });
  }

  checkprogress() {
    let k = 0;
    let forlooplength = 0;
    this.totalQuestions = 8;
    forlooplength = 7;
    for (let i = 0; i <= forlooplength; i++) {
      if (this.questionList[i].QuestionId > 0) {
        if (this.questionList[i].SurveyAnswerId > 0 && this.questionList[i].AnswerText != null && this.questionList[i].AnswerText != '') {
          k++;
        }
      }
    }
    let addpro = (100 * k) / this.totalQuestions;
    if (addpro > 0) {
      this.checkDisabled = false;
    }
    else {
      this.checkDisabled = true;
    }
    let percent = Math.round(addpro);
    $('#progress_circle').circleProgress({
      value: k / this.totalQuestions,
      size: 100.0,
      emptyFill: '#ccc',
      fill: { gradient: ['#033F6F', '#033F6F'] }
    }).on('circle-animation-progress', function (event, progress) {
      $(this).find('strong').html(percent + '%');
    });
  }
  /* checkprogress() {
    let k = 0;
    let forlooplength = 0;
    if (this.questionEntity.addi_fb != undefined && this.questionEntity.addi_fb != null && this.questionEntity.addi_fb == 'Yes') {
      this.totalQuestions = 47;
      forlooplength = 53;
    } else {
      this.totalQuestions = 38;
      forlooplength = 43;
    }
    for (let i = 1; i <= forlooplength; i++) {
      if (this.questionList[i].QuestionId > 0) {
        if (this.questionList[i].SurveyAnswerId > 0 && this.questionList[i].AnswerText != null && this.questionList[i].AnswerText != '') {
          k++;
        }
      }
    }
    let addpro = (100 * k) / this.totalQuestions;
    let percent = Math.round(addpro);
    $('#progress_circle').circleProgress({
      value: k / this.totalQuestions,
      size: 100.0,
      emptyFill: '#ccc',
      fill: { gradient: ['#033F6F', '#033F6F'] }
    }).on('circle-animation-progress', function (event, progress) {
      $(this).find('strong').html(percent + '%');
    });
  } */

  previous2() {
    this.slider2 = false;
    this.slider1 = true;
    $(window).scrollTop(0);
  }

  next2() {
    this.slider2 = false;
    this.slider3 = true;
    $(window).scrollTop(0);
    // if((this.questionList[1].AnswerText!=null && this.questionList[1].AnswerText!='')){
    //   this.slider2 = false;
    //   this.slider3 = true;
    // } else {
    //   swal({
    //     type: this.globals.commonTranslationText.common.alerts.fillTheQuestions.type,
    //     title: this.globals.commonTranslationText.common.alerts.fillTheQuestions.title,
    //     text: this.globals.commonTranslationText.common.alerts.fillTheQuestions.text,
    //     showConfirmButton: false,
    //     timer: 4000
    //   })
    // }    
  }

  previous3() {
    this.slider3 = false;
    this.slider2 = true;
    $(window).scrollTop(0);
  }

  next3() {
    this.slider3 = false;
    // this.slider4 = true;
    $(window).scrollTop(0);
  }
}
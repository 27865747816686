import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { SurveyService } from '../services/survey.service';
import { Globals } from '../globals';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { GroupDescriptor, process, SortDescriptor } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { formatDate } from '@angular/common';
declare var $: any, swal;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  userLogin: any;

  constructor(private globals: Globals, private auth: AuthService, private SurveyService: SurveyService, private router: Router) {
    if (!this.auth.isLoggedIn()) {
      router.navigate(['login']);
    }else{
      this.globals.userLogin = true;
    }
  }

  ngOnInit() {
  }

}

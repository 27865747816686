import { Injectable, Inject } from '@angular/core';
import { CanActivate, RouterStateSnapshot } from '@angular/router';
import { Router, RoutesRecognized } from '@angular/router';
import { Globals } from '.././globals';
import { JwtHelperService } from '@auth0/angular-jwt';
import { filter, pairwise } from 'rxjs/operators';
declare var $, swal: any;


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, public globals: Globals) { }

  canActivate(route, state: RouterStateSnapshot) {

    this.globals.isLoading = false;
    if (state.url.split('/')[1] == 'admin') { debugger
      window.location.href = "https://admin.openeyessurveys.com";
      return false;
    }
    if (state.url.split('/')[4] != undefined) {
      this.globals.currentLink = '/' + state.url.split('/')[1] + '/' + state.url.split('/')[2] + '/' + state.url.split('/')[3] + '/' + state.url.split('/')[4];
    } else if (state.url.split('/')[3] != undefined) {
      this.globals.currentLink = '/' + state.url.split('/')[1] + '/' + state.url.split('/')[2] + '/' + state.url.split('/')[3];
    } else if (state.url.split('/')[2] != undefined) {
      this.globals.currentLink = '/' + state.url.split('/')[1] + '/' + state.url.split('/')[2];
    } else {
      this.globals.currentLink = '/' + state.url.split('/')[1];
    }

    return true;

  }
}


import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SurveyService } from '../../services/survey.service';
declare var $, swal: any, Bloodhound: any;

@Component({
  selector: 'app-survey6',
  templateUrl: './survey6.component.html',
  styleUrls: ['./survey6.component.css']
})
export class Survey6Component implements OnInit {

  userEntity: any = {};
  submitted: boolean = false;
  questionEntity: any = {};
  slider1: boolean = true;
  slider2: boolean = false;
  slider3: boolean = false;
  slider4: boolean = false;
  slider5: boolean = false;
  slider6: boolean = false;
  slider7: boolean = false;
  slider8: boolean = false;
  slider9: boolean = false;
  slider10: boolean = false;
  showAdditionalFeedback: boolean = false;
  userEntityDisable: boolean = false;
  checkDisabled: boolean = true;
  checkDisabledToottip: string = this.globals.commonTranslationText.common.tooltip.queRequired;
  countryList;
  usStateList;
  defaultItemCountry: string = 'Select Country';
  defaultItemState: string = 'Select State';
  surveyDetails: any = {};
  id;
  showUSStates: boolean = false;
  totalQuestions: number = 39;
  checkbox_selected: number = 0;
  questionList: any = [
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 4, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 5, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 6, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 7, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 8, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 9, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 10, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 11, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 12, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 38, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 39, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 40, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 42, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 44, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 47, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 49, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 50, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 51, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 52, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 53, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null }
  ];

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute,
    private SurveyService: SurveyService) { }

  ngOnInit() {

    $(window).scrollTop(0);
    setTimeout(() => {
      const body = document.querySelector('body');
      body.style.setProperty('--screen-height', $(window).height() + "px");
    }, 100);

    $('#progress_circle').circleProgress({
      value: 0,
      size: 100.0,
      emptyFill: '#ccc',
      fill: { gradient: ['#033F6F', '#033F6F'] }
    }).on('circle-animation-progress', function (event, progress) {
      $(this).find('strong').html('0%');
    });

    // $('.owl-carousel-survey').owlCarousel({
    //   autoPlay: true,
    //   nav: true,
    //   dots: false,
    //   margin: 20,
    //   stopOnHover: true,
    //   responsiveClass: true,
    //   responsive: {
    //     0: {
    //       items: 1
    //     },
    //     1199: {
    //       items: 1
    //     }
    //   }
    // });

    this.countryList = this.globals.commonTranslationText.countryList;
    this.usStateList = this.globals.commonTranslationText.usStateList;

    let UserId = this.route.snapshot.paramMap.get('userid');
    let SurveyUserId = this.route.snapshot.paramMap.get('suid');

    this.userEntity.UserId = UserId;
    this.userEntity.SurveyUserId = SurveyUserId;
    this.userEntity.SurveyId = 6;
    this.userEntity.IsAnonymous = false;

    this.id = this.route.snapshot.url[2].path;
    this.SurveyService.getSurveyDetails(this.id)
      .then((data) => {
        this.surveyDetails = data;
        this.globals.title.setTitle(data['SurveyName']);
        this.globals.logo = data['Logo'];
        this.globals.logoURL = data['LogoURL'];
        if(new Date(this.surveyDetails.ExpiryDate) >= new Date(this.globals.usaTime)){
        } else {
          this.router.navigate(['/expired']);
        }
      this.globals.isLoading = false;
      },
      (error) => {
        this.globals.isLoading = false;
        this.router.navigate(['/pagenotfound']);
      });
  }

  getState(country) {
    if (country == 'United States of America') {
      this.showUSStates = true;
    } else {
      this.showUSStates = false;
      this.questionEntity.usstate = 'Select State';
      this.questionList[6].AnswerText = '';
    }
    if (country == 'Select Country') {
      country = '';
      this.questionEntity.usstate = 'Select State';
      this.questionList[6].AnswerText = '';
    }
    this.submitAnswer(5, country, null);
  }

  changeState(state) {
    if (state == 'Select State') {
      state = '';
    }
    this.submitAnswer(6, state, null);
  }

  AnonymousChange() {
    if (!this.userEntity.IsAnonymous) {
      this.userEntityDisable = true;
      this.userEntity.FirstName = null;
      this.userEntity.LastName = null;
      this.userEntity.EmailAddress = null;
      this.userEntity.PhoneNumber = null;
      this.userEntity.Company = null;
    } else {
      this.userEntityDisable = false;
    }
  }

  submitSurvey(SurveyStatus, surveyForm) {

    // if((this.questionEntity.involvement=='Yes' || this.questionEntity.memberLike=='Yes') && (this.userEntity.EmailAddress==null || this.userEntity.EmailAddress==undefined || this.userEntity.EmailAddress=='')){
    //   swal({
    //     type: this.globals.commonTranslationText.common.alerts.fillEmailQuestions.type,
    //     title: this.globals.commonTranslationText.common.alerts.fillEmailQuestions.title,
    //     text: this.globals.commonTranslationText.common.alerts.fillEmailQuestions.text,
    //     showConfirmButton: true,
    //     timer: 4000
    //   })
    // } else {

      this.userEntity.SurveyStatus = SurveyStatus;
      swal({
        type: this.globals.commonTranslationText.common.alerts.confirmSubmit.type,
        title: this.globals.commonTranslationText.common.alerts.confirmSubmit.title,
        text: this.globals.commonTranslationText.common.alerts.confirmSubmit.text,
        //icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: "No"
      })
      .then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          this.globals.LoadingText = 'Submitting...';
          let postData = this.userEntity;
          postData.SurveyStatus = SurveyStatus;
          this.SurveyService.submitSurvey(postData)
            .then((data) => {
              this.globals.isLoading = false;
              this.router.navigate(['/thankyou']);
            },
              (error) => {
                this.globals.isLoading = false;
                swal({
                  type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                  title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                  text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                  showConfirmButton: false,
                  timer: 4000
                })
              });
        }
      })
    //}
  }

  startSurvey1(userForm) {
    // this.slider1 = false;
    // this.slider2 = true;

    this.submitted = true;
    if (userForm.valid) {
      this.globals.isLoading = true;
      this.globals.LoadingText = 'Submitting...';
      this.SurveyService.startSurvey(this.userEntity)
        .then((data) => {
          this.userEntity.UserId = data['UserId'];
          this.userEntity.SurveyUserId = data['SurveyUserId'];
          this.questionEntity.SurveyUserId = data['SurveyUserId'];
          this.globals.isLoading = false;
          this.slider1 = false;
          this.slider2 = true;
          this.submitted = false;
        },
          (error) => {
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
  }

  submitSurveywithUser(userForm) {
    debugger
    this.submitted = true;
    if (userForm.valid) {
      $('#Preview_Modal').modal('hide');
      this.globals.isLoading = true;
      this.globals.LoadingText = 'Submitting...';
      let postData = this.userEntity;
      postData.IsAnonymous = false;
      postData.SurveyStatus = this.userEntity.SurveyStatus;
      this.SurveyService.submitSurveywithUser(postData)
        .then((data) => {
          this.globals.isLoading = false;
          this.router.navigate(['/thankyou']);
        },
          (error) => {
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
  }

  submitSurvey1(SurveyStatus) {
    this.userEntity.SurveyStatus = SurveyStatus;
    if (this.userEntity.IsAnonymous) {
      swal({
        type: this.globals.commonTranslationText.common.alerts.anonymousSubmit.type,
        title: this.globals.commonTranslationText.common.alerts.anonymousSubmit.title,
        text: this.globals.commonTranslationText.common.alerts.anonymousSubmit.text,
        //icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: "No"
      })
        .then((result) => {
          if (result.value) {
            this.globals.isLoading = true;
            this.globals.LoadingText = 'Submitting...';
            let postData = { 'SurveyUserId': this.userEntity.SurveyUserId, 'SurveyStatus': SurveyStatus, 'IsAnonymous': this.userEntity.IsAnonymous, 'EmailAddress': this.userEntity.EmailAddress };
            this.SurveyService.submitSurvey(postData)
              .then((data) => {
                this.globals.isLoading = false;
                this.router.navigate(['/thankyou']);
              },
                (error) => {
                  this.globals.isLoading = false;
                  swal({
                    type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                    title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                    text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                    showConfirmButton: false,
                    timer: 4000
                  })
                });
          } else {
            $('#Preview_Modal').modal('show');
          }
        })
    } else {
      swal({
        type: this.globals.commonTranslationText.common.alerts.confirmSubmit.type,
        title: this.globals.commonTranslationText.common.alerts.confirmSubmit.title,
        text: this.globals.commonTranslationText.common.alerts.confirmSubmit.text,
        //icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: "No"
      })
        .then((result) => {
          if (result.value) {
            this.globals.isLoading = true;
            this.globals.LoadingText = 'Submitting...';
            let postData = { 'SurveyUserId': this.userEntity.SurveyUserId, 'SurveyStatus': SurveyStatus, 'IsAnonymous': this.userEntity.IsAnonymous, 'EmailAddress': this.userEntity.EmailAddress };
            this.SurveyService.submitSurvey(postData)
              .then((data) => {
                this.globals.isLoading = false;
                this.router.navigate(['/thankyou']);
              },
                (error) => {
                  this.globals.isLoading = false;
                  swal({
                    type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                    title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                    text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                    showConfirmButton: false,
                    timer: 4000
                  })
                });
          }
        })
    }
  }

  checkAdditionalFeedback(QuestionId, AnswerText, Comment) {
    if (AnswerText == 'Yes') {
      this.showAdditionalFeedback = true;
    } else {
      this.showAdditionalFeedback = false;
    }
    this.submitAnswer(QuestionId, AnswerText, Comment);
  }

  toggleChange(QuestionId, AnswerText, Comment) {
    if (AnswerText) {
      AnswerText = 'Yes';
    } else {
      AnswerText = 'No';
    }
    this.submitAnswer(QuestionId, AnswerText, Comment);
  }

  changeCheckbox(QuestionId,idno){ debugger
    if($('#topics_cb'+idno).prop("checked")){
      if(this.checkbox_selected>2){
        swal({
          type: this.globals.commonTranslationText.common.alerts.checkboxSelect.type,
          title: this.globals.commonTranslationText.common.alerts.checkboxSelect.title,
          text: this.globals.commonTranslationText.common.alerts.checkboxSelect.text,
          showConfirmButton: true,
          timer: 4000
        })
        $('#topics_cb'+idno).prop("checked", false);
      } else {
        var AnswerText_arr = new Array();
        $("input[name='likelihood']:checked").each(function() {
            AnswerText_arr.push($(this).val());
        });      
        this.checkbox_selected = AnswerText_arr.length;
        let AnswerText = AnswerText_arr.join("$");
        this.submitAnswer(QuestionId, AnswerText, null);
      }
    } else {
      var AnswerText_arr = new Array();
      $("input[name='likelihood']:checked").each(function() {
          AnswerText_arr.push($(this).val());
      });      
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join("$");
      this.submitAnswer(QuestionId, AnswerText, null);
    }        
  }
  
  submitAnswer(QuestionId, AnswerText, Comment) {
    //this.globals.isLoading = true;
    let postData = {
      'SurveyUserId': this.userEntity.SurveyUserId,
      'SurveyAnswerId': this.questionList[QuestionId].SurveyAnswerId, 'QuestionId': QuestionId,
      'AnswerText': AnswerText, 'Comment': Comment
    };
    //console.log(postData);

    this.SurveyService.submitAnswer(postData)
      .then((data) => {
        this.questionList[QuestionId].SurveyAnswerId = data['SurveyAnswerId'];
        this.questionList[QuestionId].AnswerText = $.trim(AnswerText);
        this.questionList[QuestionId].Comment = Comment;
        this.checkprogress(true);
        //this.globals.isLoading = false;
      },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000
          })
        });
  }

  checkprogress(emailValid) { 
    let k = 0;
    let forlooplength = 0;
    let left_arr = [];
    // if (this.questionEntity.addi_fb != undefined && this.questionEntity.addi_fb != null && this.questionEntity.addi_fb == 'Yes') {
    //   this.totalQuestions = 19;
    //   forlooplength = 53;
    //   left_arr = [6,11];
    // } else {
    //   this.totalQuestions = 12;
    //   forlooplength = 53;   
    //   left_arr = [6,11,4,5,44,47,50,52,53];   
    // }
    this.totalQuestions = 18;
    forlooplength = 53;   
    left_arr = [6,11]; 

    for (let i = 1; i <= forlooplength; i++) {
      if (this.questionList[i].QuestionId > 0 && ($.inArray(this.questionList[i].QuestionId, left_arr) === -1)) {
        if (this.questionList[i].SurveyAnswerId > 0 && this.questionList[i].AnswerText != null && this.questionList[i].AnswerText != '') {
          k++;
        }
      }
    }
    
    if(k>0){
      if((this.questionEntity.involvement=='Yes' || this.questionEntity.memberLike=='Yes') && (this.userEntity.EmailAddress==undefined || this.userEntity.EmailAddress==null || this.userEntity.EmailAddress=='' || !emailValid)){
        this.checkDisabledToottip = this.globals.commonTranslationText.common.tooltip.queEmailRequired;
        this.checkDisabled = true;
      } else {
        this.checkDisabled = false;
      }      
    } else {
      this.checkDisabled = true;
    }
    
    let addpro = (100 * k) / this.totalQuestions;
    let percent = Math.round(addpro);
    $('#progress_circle').circleProgress({
      value: k / this.totalQuestions,
      size: 100.0,
      emptyFill: '#ccc',
      fill: { gradient: ['#033F6F', '#033F6F'] }
    }).on('circle-animation-progress', function (event, progress) {
      $(this).find('strong').html(percent + '%');
    });
  }

  next1() {
    this.slider1 = false;
    this.slider2 = true;
    $(window).scrollTop(0);

    let i = 1;
    let answer_arr = this.questionList[12].AnswerText.split("$");
    setTimeout(function(){ 
      $("input[name='likelihood']").each(function() {
        if(($.inArray($(this).val(), answer_arr) !== -1)){
          $('#topics_cb'+i).prop("checked", true);
        } else {
          $('#topics_cb'+i).prop("checked", false);
        }
        i++;
      }); 
    }, 100);
   
  }

  previous2() {
    this.slider2 = false;
    this.slider1 = true;
    $(window).scrollTop(0);
  }

  next2() {
    this.slider2 = false;
    this.slider3 = true;
    $(window).scrollTop(0);
    // if((this.questionList[1].AnswerText!=null && this.questionList[1].AnswerText!='')){
    //   this.slider2 = false;
    //   this.slider3 = true;
    // } else {
    //   swal({
    //     type: this.globals.commonTranslationText.common.alerts.fillTheQuestions.type,
    //     title: this.globals.commonTranslationText.common.alerts.fillTheQuestions.title,
    //     text: this.globals.commonTranslationText.common.alerts.fillTheQuestions.text,
    //     showConfirmButton: false,
    //     timer: 4000
    //   })
    // }    
  }

  previous3() {
    this.slider3 = false;
    this.slider2 = true;
    $(window).scrollTop(0);

    let i = 1;
    let answer_arr = this.questionList[12].AnswerText.split("$");
    setTimeout(function(){ 
      $("input[name='likelihood']").each(function() {
        if(($.inArray($(this).val(), answer_arr) !== -1)){
          $('#topics_cb'+i).prop("checked", true);
        } else {
          $('#topics_cb'+i).prop("checked", false);
        }
        i++;
      }); 
    }, 100);

  }

  next3() {
    this.slider3 = false;
    this.slider4 = true;
    $(window).scrollTop(0);
  }

  previous4() {
    this.slider4 = false;
    this.slider3 = true;
    $(window).scrollTop(0);
  }

  next4() {
    this.slider4 = false;
    this.slider5 = true;
    $(window).scrollTop(0);
  }

  previous5() {
    this.slider5 = false;
    this.slider4 = true;
    $(window).scrollTop(0);
  }

  next5() {
    this.slider5 = false;
    this.slider6 = true;
    $(window).scrollTop(0);
  }

  previous6() {
    this.slider6 = false;
    this.slider5 = true;
    $(window).scrollTop(0);
  }

  next6() {
    this.slider6 = false;
    this.slider7 = true;
    $(window).scrollTop(0);
  }

  previous7() {
    this.slider7 = false;
    this.slider6 = true;
    $(window).scrollTop(0);
  }

  next7() {
    this.slider7 = false;
    this.slider8 = true;
    $(window).scrollTop(0);
  }

  previous8() {
    this.slider8 = false;
    this.slider7 = true;
    $(window).scrollTop(0);
  }

  next8() {
    this.slider8 = false;
    this.slider9 = true;
    $(window).scrollTop(0);
  }

  previous9() {
    this.slider9 = false;
    this.slider8 = true;
    $(window).scrollTop(0);
  }

  next9() {
    this.slider9 = false;
    this.slider10 = true;
    $(window).scrollTop(0);
  }

  previous10() {
    this.slider10 = false;
    this.slider9 = true;
    $(window).scrollTop(0);
  }

  next4_7(){
    this.slider4 = false;
    this.slider7 = true;
    $(window).scrollTop(0);
  }

  previous7_4(){
    this.slider7 = false;
    this.slider4 = true;
    $(window).scrollTop(0);
  }

}


import { formatDate } from "@angular/common";
import { Component, ElementRef, OnInit } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Globals } from "src/app/globals";
import { SurveyService } from "src/app/services/survey.service";
import { CookieService } from "ngx-cookie-service";
declare var $: any, swal;

@Component({
  selector: "app-add-survey",
  templateUrl: "./add-survey.component.html",
  styleUrls: ["./add-survey.component.css"],
})
export class AddSurveyComponent implements OnInit {
  UUIID;
  addUpdateBtn: String = "Add";
  logoImg;
  AdminUserId;

  form = this.formBuilder.group({
    SurveyId: [""],
    UserId: [""],
    IsActive: [""],
    SurveyUUIID: ["", (Validators.required, Validators.minLength(16))],
    SurveyName: ["", (Validators.required, Validators.minLength(2))],
    SearchKeyWords: ["", (Validators.required, Validators.minLength(2))],
    ShortDescription: ["", (Validators.required, Validators.minLength(20))],
    LongDescription: ["", (Validators.required, Validators.minLength(20))],
    IntroductionText: ["", (Validators.required, Validators.minLength(20))],
    ThankyouText: [""],
    RedirectionURL: [""],
    Logo: [""],
    LogoURL: ["", Validators.required],
    StartDate: ["", Validators.required],
    ExpiryDate: ["", Validators.required],
  });

  constructor(
    private router: Router,
    private elem: ElementRef,
    private route: ActivatedRoute,
    private globals: Globals,
    private survey: SurveyService,
    private formBuilder: FormBuilder,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.UUIID = this.route.snapshot.params["suid"];
    let jwtHelper = new JwtHelperService();
    this.AdminUserId = jwtHelper.decodeToken(this.cookieService.get("token")).AdminUserId;
    if (this.UUIID) {
      this.addUpdateBtn = "Update";
      this.survey.getSurveyDetails(this.UUIID).then(
        (data) => {
          var d = [];
          d.push(data);
          d[0]["UserId"] = this.AdminUserId;
          d[0]["IsActive"] = 1;
          d[0]["StartDate"] = new Date(d[0]["StartDate"]);
          d[0]["ExpiryDate"] = new Date(d[0]["ExpiryDate"]);
          this.logoImg = d[0]["Logo"];
          d[0]["Logo"] = "";
          this.form.setValue(d[0]);
        },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      );
    }
  }

  public back() {
    this.router.navigate(["administrator"]);
  }

  public addUpdate() {
    var input = this.elem.nativeElement.querySelector("#LogoInput");
    var formData = this.form.value;
    if (!this.UUIID) {
      formData["SurveyUUIID"] = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
      formData["UserId"] = this.AdminUserId;
      formData["IsActive"] = 1;
      formData["SurveyId"] = 0;
    }
    if (input.files && input.files[0]) {
      formData["Logo"] =
        formatDate(new Date(), "yyyyMMdd", "en_US") +
        $("#LogoInput")
          .val()
          .substring($("#LogoInput").val().lastIndexOf("\\") + 1);
    } else {
      formData["Logo"] = this.logoImg;
    }
    formData["StartDate"] = formatDate(formData.StartDate, "yyyy-MM-dd hh:mm:ss", "en_US");
    formData["ExpiryDate"] = formatDate(formData.ExpiryDate, "yyyy-MM-dd hh:mm:ss", "en_US");

    if (input.files && input.files[0]) {
      var filename = $("#LogoInput").val();
      filename = formatDate(new Date(), "yyyyMMdd", "en_US") + filename.substring(filename.lastIndexOf("\\") + 1);
      //  filename = formatDate(new Date(), 'yyyyMMdd', 'en_US') + filename.substring(filename.lastIndexOf('\\') + 1);
      var ext = filename.substring(filename.lastIndexOf(".") + 1);
      //var ext = filename.substring(filename.length - 4, filename.length);
      if (
        ext == "jpg" ||
        ext == "jpeg" ||
        ext == "png" ||
        ext == "JPG" ||
        ext == "JPEG" ||
        ext == "PNG" ||
        ext == "PEG" ||
        ext == "peg"
      ) {
        var logoFile = this.elem.nativeElement.querySelector("#LogoInput").files[0];
        var fd = new FormData();
        fd.append("Logo", logoFile, filename);
        this.logoImg = filename;

        this.survey.uploadLogo(fd).then(
          (data) => {
            this.survey.addUpdateSurvey(formData).then(
              (data) => {
                swal({
                  type: "success",
                  title: "success",
                  text: "Survey added successfully.",
                  showConfirmButton: true,
                  timer: 4000,
                }).then(function () {
                  window.location.href = "administrator";
                });
              },
              (error) => {
                this.globals.isLoading = false;
                swal({
                  type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                  title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                  // text: error.error.message,
                  text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                  showConfirmButton: false,
                  timer: 4000,
                });
              }
            );
          },
          (error) => {
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              // text: error.error.message,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000,
            });
          }
        );
      } else {
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: "Invalid Logo Image File Type",
          //text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 4000,
        });
      }
    } else {
      this.survey.addUpdateSurvey(formData).then(
        (data) => {
          swal({
            type: "success",
            title: "success",
            text: "Survey added successfully.",
            showConfirmButton: true,
            timer: 4000,
          }).then(function () {
            window.location.href = "administrator";
          });
        },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            // text: error.error.message,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      );
    }
  }
}

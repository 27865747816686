import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SurveyService } from '../../services/survey.service';
declare var $, swal: any, Bloodhound: any;

@Component({
  selector: 'app-survey4',
  templateUrl: './survey4.component.html',
  styleUrls: ['./survey4.component.css']
})
export class Survey4Component implements OnInit {

  userEntity: any = {};
  submitted: boolean = false;
  questionEntity: any = {};
  slider1: boolean = true;
  slider2: boolean = false;
  slider3: boolean = false;
  showAdditionalFeedback: boolean = false;
  userEntityDisable: boolean = false;
  checkDisabled: boolean = true;
  surveyDetails: any = {};
  id;
  showUSStates: boolean = false;
  totalQuestions: number = 7;
  questionList: any = [
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 1, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 2, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 3, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 4, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 5, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 7, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 8, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 9, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 10, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 13, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 14, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 15, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 16, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 17, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 18, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 19, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 20, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 21, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 22, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 23, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 24, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 25, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 26, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 27, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 28, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 29, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 30, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 31, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 32, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 33, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 34, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 35, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 36, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 38, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 39, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 40, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 41, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 42, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 43, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 44, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 45, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 46, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 47, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 50, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 51, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 52, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 53, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },

    { 'QuestionId': 54, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 55, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 56, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 57, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 59, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 60, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 61, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 62, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 63, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },

    { 'QuestionId': 64, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 65, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 66, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 67, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 69, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 70, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 71, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 72, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 73, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },

    { 'QuestionId': 74, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 75, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 76, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 77, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 79, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 80, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 81, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 82, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 83, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },

    { 'QuestionId': 84, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 85, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 86, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 87, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 89, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 90, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 91, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 0, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null },
    { 'QuestionId': 93, 'SurveyAnswerId': 0, 'AnswerText': null, 'Comment': null }
  ];

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute,
    private SurveyService: SurveyService) { }

  ngOnInit() {

    $(window).scrollTop(0);
    setTimeout(() => {
      const body = document.querySelector('body');
      body.style.setProperty('--screen-height', $(window).height() + "px");
    }, 100);

    $('#progress_circle').circleProgress({
      value: 0,
      size: 100.0,
      emptyFill: '#ccc',
      fill: { gradient: ['#033F6F', '#033F6F'] }
    }).on('circle-animation-progress', function (event, progress) {
      $(this).find('strong').html('0%');
    });

    let UserId = this.route.snapshot.paramMap.get('userid');
    let SurveyUserId = this.route.snapshot.paramMap.get('suid');

    this.userEntity.UserId = UserId;
    this.userEntity.SurveyUserId = SurveyUserId;
    this.userEntity.SurveyId = 4;
    this.userEntity.IsAnonymous = false;

    this.id = this.route.snapshot.url[2].path;
    this.SurveyService.getSurveyDetails(this.id)
      .then((data) => {
        this.surveyDetails = data;
        this.globals.title.setTitle(data['SurveyName']);
        this.globals.logoURL = data['LogoURL'];
        if(new Date(this.surveyDetails.ExpiryDate) >= new Date(this.globals.usaTime)){
        } else {
          this.router.navigate(['/expired']);
        }
      this.globals.isLoading = false;
      },
      (error) => {
        this.globals.isLoading = false;
        this.router.navigate(['/pagenotfound']);
      });
  }

  submitSurveywithUser(userForm) {
    this.submitted = true;
    if (userForm.valid) {
      $('#Preview_Modal').modal('hide');
      this.globals.isLoading = true;
      this.globals.LoadingText = 'Submitting...';
      let postData = this.userEntity;
      postData.IsAnonymous = false;
      postData.SurveyStatus = this.userEntity.SurveyStatus;
      this.SurveyService.submitSurveywithUser(postData)
        .then((data) => {
          this.globals.isLoading = false;
          this.router.navigate(['/thankyou']);
        },
          (error) => {
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000
            })
          });
    }
  }

  submitSurvey(SurveyStatus, surveyForm) {
    this.userEntity.SurveyStatus = SurveyStatus;
    swal({
      type: this.globals.commonTranslationText.common.alerts.confirmSubmit.type,
      title: this.globals.commonTranslationText.common.alerts.confirmSubmit.title,
      text: this.globals.commonTranslationText.common.alerts.confirmSubmit.text,
      //icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    })
    .then((result) => {
      if (result.value) {
        this.globals.isLoading = true;
        this.globals.LoadingText = 'Submitting...';
        let postData = this.userEntity;
        postData.SurveyStatus = SurveyStatus;
        this.SurveyService.submitSurvey(postData)
          .then((data) => {
            this.globals.isLoading = false;
            this.router.navigate(['/thankyou']);
          },
            (error) => {
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000
              })
            });
      }
    })
  }

  submitAnswer(QuestionId, AnswerText, Comment) {
    let postData = {
      'SurveyUserId': this.userEntity.SurveyUserId,
      'SurveyAnswerId': this.questionList[QuestionId].SurveyAnswerId, 'QuestionId': QuestionId,
      'AnswerText': AnswerText, 'Comment': Comment
    };
    this.SurveyService.submitAnswer(postData)
      .then((data) => {
        this.questionList[QuestionId].SurveyAnswerId = data['SurveyAnswerId'];
        this.questionList[QuestionId].AnswerText = $.trim(AnswerText);
        this.questionList[QuestionId].Comment = Comment;
        this.checkprogress();
      },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000
          })
        });
  }

  checkprogress() {
    let k = 0;
    let forlooplength = 0;
    if((this.questionEntity.tedxEvent1=='Very dissatisfied' || this.questionEntity.tedxEvent1=='Dissatisfied') || (this.questionEntity.tedxEvent2=='Very dissatisfied' || this.questionEntity.tedxEvent2=='Dissatisfied') || (this.questionEntity.tedxEvent3=='Very dissatisfied' || this.questionEntity.tedxEvent3=='Dissatisfied') || (this.questionEntity.tedxEvent4=='Very dissatisfied' || this.questionEntity.tedxEvent4=='Dissatisfied')){
      this.totalQuestions = 8;
      forlooplength = 93;
    } else {
      this.totalQuestions = 7;
      forlooplength = 92;
    }
    for (let i = 84; i <= forlooplength; i++) {
      if (this.questionList[i].QuestionId > 0) {
        if (this.questionList[i].SurveyAnswerId > 0 && this.questionList[i].AnswerText != null && this.questionList[i].AnswerText != '') {
          k++;
        }
      }
    }
    if(k>0){
      this.checkDisabled = false;
    } else {
      this.checkDisabled = true;
    }
    let addpro = (100 * k) / this.totalQuestions;
    let percent = Math.round(addpro);
    $('#progress_circle').circleProgress({
      value: k / this.totalQuestions,
      size: 100.0,
      emptyFill: '#ccc',
      fill: { gradient: ['#033F6F', '#033F6F'] }
    }).on('circle-animation-progress', function (event, progress) {
      $(this).find('strong').html(percent + '%');
    });
  }

  next1() {
    this.slider1 = false;
    this.slider2 = true;
    $(window).scrollTop(0);
  }

  previous2() {
    this.slider2 = false;
    this.slider1 = true;
    $(window).scrollTop(0);
  }

  next2() { 
    this.slider2 = false;
    this.slider3 = true;
    $(window).scrollTop(0);
  }

  previous3() {
    this.slider3 = false;
    this.slider2 = true;
    $(window).scrollTop(0);
  }

}

import { Observable } from "rxjs";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { State } from "@progress/kendo-data-query";
import { Globals } from "src/app/globals";
import { UserService } from "../../../services/user.service";
import { Validators, FormBuilder } from "@angular/forms";
declare var $: any, swal;

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.css"],
})
export class RegistrationComponent implements OnInit {
  @Input("decodedToken") decodedToken;
  @Output("overview") overview = new EventEmitter();

  userId: number;
  allUsers: any;
  addUpdateBtn: String = "Add";
  public view: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10,
  };
  public opened = false;

  form = this.formBuilder.group({
    AdminUserId: [""],
    IsActive: [""],
    SurveyKeyword: ["", [Validators.required, Validators.minLength(2)]],
    EmailAddress: [
      "",
      [
        Validators.required,
        Validators.email,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    ],
    // EmailAddress: [
    //   "",
    //   (Validators.required,
    //   Validators.email,
    //   Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")),
    // ],
    Password: ["", [Validators.required, Validators.minLength(6)]],
  });

  constructor(
    private globals: Globals,
    private user: UserService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    $("#password-show").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      if ($("#password").attr("type") == "password") {
        $("#password").attr("type", "text");
      } else {
        $("#password").attr("type", "password");
      }
    });

    this.userId = this.decodedToken["AdminUserId"];
    this.getAllUsers();
  }

  clickPassword() {
    $("#password-show").toggleClass("fa-eye fa-eye-slash");
    if ($("#password").attr("type") == "password") {
      $("#password").attr("type", "text");
    } else {
      $("#password").attr("type", "password");
    }
  }

  getAllUsers() {
    //this.globals.isLoading = true;
    this.user.getAll().then(
      (data) => {
        this.allUsers = data;
        this.userLoadItems();
        this.globals.isLoading = false;
      },
      (error) => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong
            .type,
          title:
            this.globals.commonTranslationText.common.alerts.somethingWrong
              .title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong
            .text,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );
  }

  public userPageChange(event: PageChangeEvent): void {
    // change page
    this.state.skip = event.skip;
    this.state.take = event.take;
    this.userLoadItems();
  }

  private userLoadItems(): void {
    // load data bases on page
    this.view = {
      data: this.allUsers.slice(
        this.state.skip,
        this.state.skip + this.state.take
      ),
      total: this.allUsers.length,
    };
  }

  public addUpdate() {
    var formData = this.form.value;
    formData["UserId"] = this.userId;
    if (formData["AdminUserId"] == "" || formData["AdminUserId"] == null) {
      formData["AdminUserId"] = 0;
      formData["IsActive"] = 1;
    }
    //alert((JSON.stringify(formData)));
    this.user.addUpdate(JSON.stringify(formData)).then(
      (data) => {
        if (data["message"] == "Successful") {
          if (formData["AdminUserId"] == "0") {
            this.allUsers.push(formData);
          } else {
            this.allUsers = this.allUsers.map((p) =>
              p.AdminUserId !== this.form.get("AdminUserId").value
                ? p
                : {
                    ...p,
                    EmailAddress: this.form.get("EmailAddress").value,
                    Password: this.form.get("Password").value,
                    SurveyKeyword: this.form.get("SurveyKeyword").value,
                  }
            );
          }
          this.overview.emit();
          this.userLoadItems();
          this.close();
          this.globals.isLoading = false;
          swal({
            type: "success",
            title: "success",
            text: "Record updated successfully.",
            showConfirmButton: false,
            timer: 4000,
          });
        }
      },
      (error) => {
        this.getAllUsers();
        this.close();
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong
            .type,
          title:
            this.globals.commonTranslationText.common.alerts.somethingWrong
              .title,
          text: error.error.message,
          //text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );
  }

  public open(e) {
    this.addUpdateBtn = "Update";
    this.form.setValue(this.allUsers[e]);
    this.opened = true;
  }

  public close() {
    this.opened = false;
    this.addUpdateBtn = "Add";
    this.form.reset();
  }

  public delete(e) {
    swal
      .fire({
        title: "Delete",
        text: "Are you sure you want to Delete?",
        icon: "warning",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
      })
      .then((result) => {
        if (result.value) {
          var data = {
            AdminUserId: this.allUsers[e]["AdminUserId"],
            UserId: this.userId,
          };
          this.user.delete(JSON.stringify(data)).then(
            (data) => {
              if (data["message"] == "Delete successfully") {
                this.allUsers = this.allUsers.filter(
                  (array) =>
                    array.AdminUserId != this.allUsers[e]["AdminUserId"]
                );
                this.userLoadItems();
                this.overview.emit();
                this.globals.isLoading = false;
                swal({
                  type: "success",
                  title: "success",
                  text: "Record Deleted successfully.",
                  showConfirmButton: false,
                  timer: 4000,
                });
              }
            },
            (error) => {
              this.getAllUsers();
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts
                  .somethingWrong.type,
                title:
                  this.globals.commonTranslationText.common.alerts
                    .somethingWrong.title,
                text: error.error.message,
                showConfirmButton: false,
                timer: 4000,
              });
            }
          );
        }
      });
  }
}

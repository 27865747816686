import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Globals } from "src/app/globals";
import { AuthService } from "src/app/services/auth.service";
import { UserService } from "../../services/user.service";
import { CookieService } from "ngx-cookie-service";
declare var $: any, swal;

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.css"],
})
export class AdminDashboardComponent implements OnInit {
  activeUsers: number;
  activeSurveys: number;
  totalResponses: number;
  decodedToken: any;

  constructor(
    private globals: Globals,
    private auth: AuthService,
    private router: Router,
    private user: UserService,
    private cookieService: CookieService
  ) {
    if (!this.auth.isLoggedIn()) {
      router.navigate(["login"]);
    } else {
      let jwtHelper = new JwtHelperService();
      this.decodedToken = jwtHelper.decodeToken(this.cookieService.get("token"));
      this.decodedToken.Role == 1 ? this.router.navigate(["administrator"]) : this.router.navigate(["dashboard"]);
      this.globals.userLogin = true;
    }
  }

  ngOnInit() {
    this.overview();
  }

  overview() {
    //this.globals.isLoading = true;
    this.user.overview().then(
      (data) => {
        this.activeUsers = data["TotalUniqueUsers"][0]["User"];
        this.activeSurveys = data["TotalUniqueSurvey"][0]["Survey"];
        this.totalResponses = data["TotalResponses"][0]["Responses"];
        this.globals.isLoading = false;
      },
      (error) => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { Globals } from "../../globals";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { SurveyService } from "../../services/survey.service";
declare var $, swal: any, Bloodhound: any;

@Component({
  selector: "app-survey16",
  templateUrl: "./survey16.component.html",
  styleUrls: ["./survey16.component.css"],
})
export class Survey16Component implements OnInit {
  userEntity: any = {};
  page_no = 1;
  submitted: boolean = false;
  questionEntity: any = {};
  slider1: boolean = true;
  slider2: boolean = false;
  slider3: boolean = false;
  checkDisabled: boolean = true;
  showAdditionalFeedback: boolean = false;
  userEntityDisable: boolean = false;
  language: string = "English";
  countryList;
  usStateList;
  surveyDetails;
  id;
  regions;
  pays;
  quality;
  importants;
  tests;
  stepArr;
  communicateArr;
  checkbox_selected: number = 0;
  totalQuestions: number = 8;
  questionList: any = [
    { QuestionId: 256, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 257, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 258, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 259, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 260, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 261, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 262, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 263, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 264, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 265, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 266, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 267, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 268, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 269, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 270, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 271, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 272, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 273, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 274, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 275, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 276, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 277, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 278, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 279, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 280, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 281, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 282, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 283, SurveyAnswerId: 0, AnswerText: null, Comment: null },
  ];

  constructor(
    public globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private SurveyService: SurveyService
  ) {}

  ngOnInit() {
    $(window).scrollTop(0);
    setTimeout(() => {
      const body = document.querySelector("body");
      body.style.setProperty("--screen-height", $(window).height() + "px");
    }, 100);

    $("#progress_circle")
      .circleProgress({
        value: 0,
        size: 70.0,
        emptyFill: "#ccc",
        fill: { gradient: ["#033F6F", "#033F6F"] },
      })
      .on("circle-animation-progress", function (event, progress) {
        $(this).find("strong").html("0%");
      });

    this.userEntity.UserId = 0;
    this.userEntity.SurveyUserId = this.route.snapshot.paramMap.get("suid");
    this.userEntity.SurveyId = 8;
    this.userEntity.IsAnonymous = true;

    this.id = this.route.snapshot.url[2].path;
    this.SurveyService.getSurveyDetails(this.id).then(
      (data) => {
        this.surveyDetails = data;
        if (new Date(this.surveyDetails.ExpiryDate) >= new Date(this.globals.usaTime)) {
          this.globals.title.setTitle(
            "Workforce Skills Survey for Credentials Issuers by Association for Testing Professionals"
          );
          this.globals.logo = data["Logo"];
          this.globals.logoURL = data["LogoURL"];
        } else {
          this.router.navigate(["/expired"]);
        }
        this.globals.isLoading = false;
      },
      (error) => {
        this.globals.isLoading = false;
        this.router.navigate(["/pagenotfound"]);
      }
    );
  }

  changeLanguage(l) {
    this.language = l;
  }

  AnonymousChange() {
    if (!this.userEntity.IsAnonymous) {
      this.userEntityDisable = true;
      this.userEntity.FirstName = null;
      this.userEntity.LastName = null;
      this.userEntity.EmailAddress = null;
      this.userEntity.PhoneNumber = null;
      this.userEntity.Company = null;
    } else {
      this.userEntityDisable = false;
    }
  }

  startSurvey(userForm) {
    this.submitted = true;
    if (userForm.valid) {
      this.globals.isLoading = true;
      this.SurveyService.startSurvey(this.userEntity).then(
        (data) => {
          this.userEntity.UserId = data["UserId"];
          this.userEntity.SurveyUserId = data["SurveyUserId"];
          this.questionEntity.SurveyUserId = data["SurveyUserId"];
          this.globals.isLoading = false;
          this.slider1 = false;
          this.slider2 = true;
          this.submitted = false;
          //console.log(this.questionEntity);
        },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      );
    }
  }

  changeCheckbox(QuestionId, idno) {
    if ($("#topics_cb" + idno).prop("checked")) {
      var AnswerText_arr = new Array();
      $("input[name='region']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      this.regions = AnswerText;
      if ($("#topics_cb11").prop("checked")) {
        this.submitAnswer(QuestionId, AnswerText, this.questionEntity.regioncomment);
      } else {
        this.submitAnswer(QuestionId, AnswerText, null);
      }
    } else {
      var AnswerText_arr = new Array();
      $("input[name='region']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      if ($("#topics_cb11").prop("checked")) {
        this.submitAnswer(QuestionId, AnswerText, this.questionEntity.regioncomment);
      } else {
        this.submitAnswer(QuestionId, AnswerText, null);
      }
    }
  }

  changeCheckbox1(QuestionId, idno) {
    if ($("#test_" + idno).prop("checked")) {
      var AnswerText_arr = new Array();
      $("input[name='test']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      this.tests = AnswerText;
      if ($("#q6o20").prop("checked")) {
        this.submitAnswer(QuestionId, AnswerText, this.questionEntity.testcomment);
      } else {
        this.submitAnswer(QuestionId, AnswerText, null);
      }
    } else {
      var AnswerText_arr = new Array();
      $("input[name='test']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      if ($("#q6o20").prop("checked")) {
        this.submitAnswer(QuestionId, AnswerText, this.questionEntity.testcomment);
      } else {
        this.submitAnswer(QuestionId, AnswerText, null);
      }
    }
  }

  changeCheckbox2(QuestionId, idno) {
    if ($("#q7o" + idno).prop("checked")) {
      var AnswerText_arr = new Array();
      $("input[name='q7step']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      this.stepArr = AnswerText;
      //console.log(this.stepArr);
      if ($("#q7o").prop("checked")) {
        this.submitAnswer(QuestionId, AnswerText, this.questionEntity.stepcomment);
      } else {
        this.submitAnswer(QuestionId, AnswerText, null);
      }
    } else {
      var AnswerText_arr = new Array();
      $("input[name='q7step']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      if ($("#q7o").prop("checked")) {
        this.submitAnswer(QuestionId, AnswerText, this.questionEntity.stepcomment);
      } else {
        this.submitAnswer(QuestionId, AnswerText, null);
      }
    }
  }

  changeCheckbox3(QuestionId, idno) {
    if ($("#q8o" + idno).prop("checked")) {
      var AnswerText_arr = new Array();
      $("input[name='q8communicate']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      this.communicateArr = AnswerText;
      if ($("#q8o").prop("checked")) {
        this.submitAnswer(QuestionId, AnswerText, this.questionEntity.communicatecomment);
      } else {
        this.submitAnswer(QuestionId, AnswerText, null);
      }
    } else {
      var AnswerText_arr = new Array();
      $("input[name='q8communicate']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      if ($("#q8o").prop("checked")) {
        this.submitAnswer(QuestionId, AnswerText, this.questionEntity.communicatecomment);
      } else {
        this.submitAnswer(QuestionId, AnswerText, null);
      }
    }
  }

  changeCheckbox4(QuestionId, idno) {
    if ($("#q110" + idno).prop("checked")) {
      var AnswerText_arr = new Array();
      $("input[name='pay']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      this.pays = AnswerText;
      this.submitAnswer(QuestionId, AnswerText, null);
    } else {
      var AnswerText_arr = new Array();
      $("input[name='pay']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      this.submitAnswer(QuestionId, AnswerText, null);
    }
  }

  changeCheckbox5(QuestionId, idno) {
    if ($("#q12o" + idno).prop("checked")) {
      var AnswerText_arr = new Array();
      $("input[name='qualities']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      this.quality = AnswerText;
      this.submitAnswer(QuestionId, AnswerText, null);
    } else {
      var AnswerText_arr = new Array();
      $("input[name='qualities']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      this.submitAnswer(QuestionId, AnswerText, null);
    }
  }

  submitSurveywithUser(userForm) {
    debugger;
    this.submitted = true;
    if (userForm.valid) {
      $("#Preview_Modal").modal("hide");
      this.globals.isLoading = true;
      let postData = this.userEntity;
      postData.IsAnonymous = false;
      postData.SurveyStatus = this.userEntity.SurveyStatus;
      this.SurveyService.submitSurveywithUser(postData).then(
        (data) => {
          this.globals.isLoading = false;
          this.router.navigate(["/thankyou"]);
        },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      );
    }
  }

  submitSurvey(SurveyStatus) {
    this.userEntity.SurveyStatus = SurveyStatus;
    this.globals.surveyId = 16;
    if (this.userEntity.IsAnonymous) {
      swal({
        type: this.globals.commonTranslationText.common.alerts.confirmSubmit.type,
        title: this.globals.commonTranslationText.common.alerts.confirmSubmit.title,
        text: this.globals.commonTranslationText.common.alerts.confirmSubmit.text,
        //icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          let postData = {
            SurveyUserId: this.userEntity.SurveyUserId,
            SurveyStatus: SurveyStatus,
            IsAnonymous: this.userEntity.IsAnonymous,
            EmailAddress: this.userEntity.EmailAddress,
            SurveyId: this.userEntity.SurveyId,
            UserId: this.userEntity.UserId,
          };
          this.SurveyService.submitSurvey(postData).then(
            (data) => {
              this.globals.isLoading = false;
              this.router.navigate(["/thankyou"]);
            },
            (error) => {
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000,
              });
            }
          );
        } else {
          $("#Preview_Modal").modal("show");
        }
      });
    } else {
      swal({
        type: this.globals.commonTranslationText.common.alerts.confirmSubmit.type,
        title: this.globals.commonTranslationText.common.alerts.confirmSubmit.title,
        text: this.globals.commonTranslationText.common.alerts.confirmSubmit.text,
        //icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          let postData = {
            SurveyUserId: this.userEntity.SurveyUserId,
            SurveyStatus: SurveyStatus,
            IsAnonymous: this.userEntity.IsAnonymous,
            EmailAddress: this.userEntity.EmailAddress,
            SurveyId: this.userEntity.SurveyId,
            UserId: this.userEntity.UserId,
          };
          this.SurveyService.submitSurvey(postData).then(
            (data) => {
              this.globals.isLoading = false;
              this.router.navigate(["/thankyou"]);
            },
            (error) => {
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000,
              });
            }
          );
        }
      });
    }
  }

  checkAdditionalFeedback(QuestionId, AnswerText, Comment) {
    if (AnswerText == "Yes") {
      this.showAdditionalFeedback = true;
    } else {
      this.showAdditionalFeedback = false;
    }
    this.submitAnswer(QuestionId, AnswerText, Comment);
  }

  toggleChange(QuestionId, AnswerText, Comment) {
    if (AnswerText) {
      AnswerText = "Yes";
    } else {
      AnswerText = "No";
    }
    this.submitAnswer(QuestionId, AnswerText, Comment);
  }

  submitAnswer(QuestionId, AnswerText, Comment) {
    var index = this.questionList.findIndex((x) => x.QuestionId === QuestionId);
    let postData = {
      SurveyUserId: this.userEntity.SurveyUserId,
      SurveyAnswerId: this.questionList[index].SurveyAnswerId,
      QuestionId: QuestionId,
      AnswerText: AnswerText,
      Comment: Comment,
    };

    this.SurveyService.submitAnswer(postData).then(
      (data) => {
        this.questionList[index].SurveyAnswerId = data["SurveyAnswerId"];
        this.questionList[index].AnswerText = $.trim(AnswerText);
        this.questionList[index].Comment = $.trim(Comment);
        this.checkprogress();
      },
      (error) => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );
  }

  checkprogress() {
    let k = 0;
    let forlooplength = 0;
    this.totalQuestions = 25;
    forlooplength = 24;

    //For Dynamic Question
    if (this.questionEntity.q21 === "Yes") {
      this.totalQuestions++;
      forlooplength++;
    }
    // if (this.questionEntity.q21 === "No") {
    //   this.totalQuestions--;
    //   forlooplength--;
    // }
    if (this.questionEntity.q24 === "No") {
      this.totalQuestions++;
      forlooplength++;
    }
    // if (this.questionEntity.q24 === "Yes") {
    //   this.totalQuestions--;
    //   forlooplength--;
    // }

    for (let i = 0; i <= forlooplength; i++) {
      if (this.questionList[i].QuestionId > 0) {
        if (
          this.questionList[i].SurveyAnswerId > 0 &&
          this.questionList[i].AnswerText != null &&
          this.questionList[i].AnswerText != ""
        ) {
          k++;
        }
      }
    }
    let addpro = (100 * k) / this.totalQuestions;
    if (addpro > 0) {
      this.checkDisabled = false;
    } else {
      this.checkDisabled = true;
    }
    let percent = Math.round(addpro);
    $("#progress_circle")
      .circleProgress({
        value: k / this.totalQuestions,
        size: 70.0,
        emptyFill: "#ccc",
        fill: { gradient: ["#033F6F", "#033F6F"] },
      })
      .on("circle-animation-progress", function (event, progress) {
        $(this)
          .find("strong")
          .html(percent + "%");
      });
  }

  next1() {
    this.page_no = 2;
    this.slider2 = true;
    this.slider1 = false;
    $(window).scrollTop(0);
  }

  previous2() {
    this.page_no = 1;
    this.slider2 = false;
    this.slider1 = true;
    $(window).scrollTop(0);
  }

  next2() {
    this.page_no = 3;
    this.slider2 = false;
    this.slider3 = true;
    $(window).scrollTop(0);
  }

  previous3() {
    this.page_no = 2;
    this.slider3 = false;
    this.slider2 = true;
    $(window).scrollTop(0);
  }
}

import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
declare var $: any;

@Component({
  selector: 'app-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.css']
})
export class ExpiredComponent implements OnInit {

  aerexperts: boolean = false;
  theopeneyes: boolean = false;
  examity: boolean = false;
  surpass: boolean = false;
  getyardstick: boolean = false;
  prolydian: boolean = false;
  trueability: boolean = false;
  ata: boolean = false;
  kryteriononline: boolean = false;
  atp: boolean = false;

  constructor(public globals: Globals) { }

  ngOnInit() {
    $(window).scrollTop(0);
    setTimeout(function () {
      window.location.href = '/home';
    }, 30000);
    
    //alert(this.globals.surveyId);
    
    if (this.globals.surveyId == 1) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = true;
      this.surpass = true;
      this.getyardstick = true;
      this.prolydian = true;
      this.trueability = true;
      this.ata = true;
      this.kryteriononline = true;
      this.atp = false;
    } else if (this.globals.surveyId == 2) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = true;
      this.surpass = true;
      this.getyardstick = true;
      this.prolydian = true;
      this.trueability = true;
      this.ata = true;
      this.kryteriononline = true;
      this.atp = false;
    } else if (this.globals.surveyId == 3) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = true;
      this.surpass = true;
      this.getyardstick = true;
      this.prolydian = true;
      this.trueability = true;
      this.ata = true;
      this.kryteriononline = true;
      this.atp = false;
    } else if (this.globals.surveyId == 4) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = true;
      this.surpass = true;
      this.getyardstick = true;
      this.prolydian = true;
      this.trueability = true;
      this.ata = true;
      this.kryteriononline = true;
      this.atp = false;
    } else if (this.globals.surveyId == 5) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = true;
      this.surpass = true;
      this.getyardstick = true;
      this.prolydian = true;
      this.trueability = true;
      this.ata = true;
      this.kryteriononline = true;
      this.atp = false;
    } else if (this.globals.surveyId == 6) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = true;
      this.surpass = true;
      this.getyardstick = true;
      this.prolydian = true;
      this.trueability = true;
      this.ata = true;
      this.kryteriononline = true;
      this.atp = false;
    } else if (this.globals.surveyId == 7) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = false;
      this.surpass = false;
      this.getyardstick = false;
      this.prolydian = false;
      this.trueability = false;
      this.ata = false;
      this.kryteriononline = false;
      this.atp = false;
    } else if (this.globals.surveyId == 8) {
      this.aerexperts = false;
      this.theopeneyes = true;
      this.examity = false;
      this.surpass = false;
      this.getyardstick = false;
      this.prolydian = false;
      this.trueability = false;
      this.ata = false;
      this.kryteriononline = false;
      this.atp = true;
    } else if (this.globals.surveyId == 9) {
      this.aerexperts = false;
      this.theopeneyes = true;
      this.examity = false;
      this.surpass = false;
      this.getyardstick = false;
      this.prolydian = false;
      this.trueability = false;
      this.ata = false;
      this.kryteriononline = false;
      this.atp = true;
    } else if (this.globals.surveyId == 10) {
      this.aerexperts = false;
      this.theopeneyes = true;
      this.examity = false;
      this.surpass = false;
      this.getyardstick = false;
      this.prolydian = false;
      this.trueability = false;
      this.ata = false;
      this.kryteriononline = false;
      this.atp = true;
    } else if (this.globals.surveyId == 11) {
      this.aerexperts = false;
      this.theopeneyes = true;
      this.examity = false;
      this.surpass = false;
      this.getyardstick = false;
      this.prolydian = false;
      this.trueability = false;
      this.ata = false;
      this.kryteriononline = false;
      this.atp = true;
    } else {
      this.theopeneyes = true;
      /*  this.aerexperts = true;
       this.examity = true;
       this.surpass = true;
       this.getyardstick = true;
       this.prolydian = true;
       this.trueability = true;
       this.ata = true;
      this.kryteriononline = true;
       this.atp = false; */
    }

  }

}

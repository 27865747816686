import { formatDate } from "@angular/common";
import { Component, ElementRef, OnInit } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Globals } from "src/app/globals";
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { State } from "@progress/kendo-data-query";
import { SurveyService } from "src/app/services/survey.service";
import { CookieService } from "ngx-cookie-service";
declare var $: any, swal;

@Component({
  selector: "app-survey-questions",
  templateUrl: "./survey-questions.component.html",
  styleUrls: ["./survey-questions.component.css"],
})
export class SurveyQuestionsComponent implements OnInit {
  UUIID;
  AdminUserId;
  surveyData: any;
  surveyQuestions: any;
  SurveyId;

  public opened = false;

  public view: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10,
  };

  form = this.formBuilder.group({
    Logo: ["", Validators.required],
    SurveyId: ["", Validators.required],
  });

  constructor(
    private router: Router,
    private elem: ElementRef,
    private route: ActivatedRoute,
    private globals: Globals,
    private survey: SurveyService,
    private formBuilder: FormBuilder,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.UUIID = this.route.snapshot.params["suid"];
    let jwtHelper = new JwtHelperService();
    this.AdminUserId = jwtHelper.decodeToken(this.cookieService.get("token")).AdminUserId;
    this.getSurveyDetails();
  }

  getSurveyDetails() {
    this.survey.getSurveyDetails(this.UUIID).then(
      (data) => {
        this.surveyData = data;
        this.SurveyId = this.surveyData["SurveyId"];
        this.getSurveysQuestions(this.surveyData["SurveyId"]);
        this.globals.isLoading = false;
      },
      (error) => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: error.error.message,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );
  }

  getSurveysQuestions(SurveyId) {
    //this.globals.isLoading = true;

    this.survey.getSurveyQuiestion(SurveyId).then(
      (data) => {
        if (!data["message"]) {
          this.surveyQuestions = data;
          this.surveyLoadItems();
          this.globals.isLoading = false;
        }
      },
      (error) => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: error.error.message,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );
  }

  public userPageChange(event: PageChangeEvent): void {
    // change page
    this.state.skip = event.skip;
    this.state.take = event.take;
    this.surveyLoadItems();
  }

  private surveyLoadItems(): void {
    // load data bases on page
    this.view = {
      data: this.surveyQuestions.slice(this.state.skip, this.state.skip + this.state.take),
      total: this.surveyQuestions.length,
    };
  }

  public back() {
    this.router.navigate(["administrator"]);
  }

  importExcel() {
    var input = this.elem.nativeElement.querySelector("#importExcel");

    if (input.files && input.files[0]) {
      var filename = $("#importExcel").val();
      filename = filename.substring(filename.lastIndexOf("\\") + 1);
      var ext = filename.substring(filename.lastIndexOf(".") + 1);

      if (ext == "xlsx" || ext == "xls") {
        var File = this.elem.nativeElement.querySelector("#importExcel").files[0];
        var fd = new FormData();
        fd.append("uploadFile", File, formatDate(new Date(), "yyyyMMdd", "en_US") + filename);
        fd.append("SurveyId", this.SurveyId);

        this.survey.importQuestion(fd).then(
          (data) => {
            this.close("cancel");
            swal({
              // type: 'success',
              // title: 'success',
              html:
                "Uploaded Questions: " +
                data["message"]["uploaded"] +
                " <br/>" +
                "Not Uploaded Questions: " +
                data["message"]["notuploaded"],
              showConfirmButton: true,
              timer: 6000,
            });
            this.getSurveyDetails();
          },
          (error) => {
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: error.error.message,
              //text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
              showConfirmButton: false,
              timer: 4000,
            });
          }
        );
      } else {
        this.close("cancel");
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: "Invalid File Type",
          //text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 4000,
        });
      }
    }
  }

  public close(status) {
    //console.log(`Dialog result: ${status}`);
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }

  downloadFile() {
    //const blob = new Blob([data], { type: 'application/octet-stream' });
    // const url= window.URL.createObjectURL(blob);
    const url = "assets/demoExcel/Demo_question_list.xlsx";
    window.open(url);
  }
}

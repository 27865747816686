import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Globals } from "./globals";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpInterceptorClassService } from "./http-interceptor-class.service";

// components
import { Survey1Component } from "./user/survey1/survey1.component";
import { Survey2Component } from "./user/survey2/survey2.component";
import { Survey3Component } from "./user/survey3/survey3.component";
import { Survey4Component } from "./user/survey4/survey4.component";
import { Survey5Component } from "./user/survey5/survey5.component";
import { Survey6Component } from "./user/survey6/survey6.component";
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./user/home/home.component";
import { IntroductionComponent } from "./user/introduction/introduction.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { CookiePolicyComponent } from "./cookie-policy/cookie-policy.component";
import { TermsOfUseComponent } from "./terms-of-use/terms-of-use.component";
import { ThankyouComponent } from "./user/thankyou/thankyou.component";
import { ExpiredComponent } from "./user/expired/expired.component";
import { Survey7Component } from "./user/survey7/survey7.component";
import { Survey8Component } from "./user/survey8/survey8.component";
import { Survey9Component } from "./user/survey9/survey9.component";
import { Survey10Component } from "./user/survey10/survey10.component";
import { Survey11Component } from "./user/survey11/survey11.component";
import { LoginComponent } from "./admin/login/login.component";
import { DashboardComponent } from "./admin/client-dashboard/client-dashboard.component";
import { Survey13Component } from "./user/survey13/survey13.component";
import { Survey14Component } from "./user/survey14/survey14.component";
import { AdminComponent } from "./admin/admin.component";
import { SurveyQuestionsComponent } from "./admin/admin-dashboard/survey-questions/survey-questions.component";
import { AddSurveyComponent } from "./admin/admin-dashboard/add-survey/add-survey.component";
import { Survey15Component } from "./user/survey15/survey15.component";
import { Survey16Component } from "./user/survey16/survey16.component";
import { Survey17Component } from "./user/survey17/survey17.component";
import { Survey18Component } from "./user/survey18/survey18.component";

// services
import { AuthGuard } from "./services/auth-guard.service";
import { SurveyService } from "./services/survey.service";

const routes: Routes = [
  { path: "home", component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: ":survey/introduction/:id",
    component: IntroductionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "VCRP/survey/2c74d40d-84ba-4d51-b98f-0355f190914c/:userid/:suid",
    component: Survey1Component,
    canActivate: [AuthGuard],
  },
  {
    path: "VCRP/survey/43fc26ec-4bd7-447b-bd4b-caf0a3c25f18/:userid/:suid",
    component: Survey2Component,
    canActivate: [AuthGuard],
  },
  {
    path: "VCRP/survey/54618d00-56ec-11ea-abd5-6f99e4f3bff5/:userid/:suid",
    component: Survey3Component,
    canActivate: [AuthGuard],
  },
  {
    path: "VCRP/survey/297383f0-57d7-11ea-9fc4-0ddbffad35aa/:userid/:suid",
    component: Survey4Component,
    canActivate: [AuthGuard],
  },
  {
    path: "VCRP/survey/26568e14-e621-4265-8683-f8beb75f4850/:userid/:suid",
    component: Survey5Component,
    canActivate: [AuthGuard],
  },
  {
    path: "VCRP/survey/ccf87460-57d7-11ea-8217-7ddc5e4b89b3/:userid/:suid",
    component: Survey6Component,
    canActivate: [AuthGuard],
  },
  {
    path: "YIR/survey/rdf87460-57d7-11ea-8217-7ddc5e4b89b3/:userid/:suid",
    component: Survey7Component,
    canActivate: [AuthGuard],
  },
  {
    path: "ATP/survey/wfs87460-57d7-11ea-8217-7ddc5e4b89b3/:userid/:suid",
    component: Survey8Component,
    canActivate: [AuthGuard],
  },
  {
    path: "ATP/survey/atp87460-57d7-11ea-8217-7ddc5e4b89b3/:userid/:suid",
    component: Survey9Component,
    canActivate: [AuthGuard],
  },
  {
    path: "ATP/survey/atp37460-57d7-11ea-8217-7ddc5e4b89b3/:userid/:suid",
    component: Survey10Component,
    canActivate: [AuthGuard],
  },
  {
    path: "ATP/survey/atp47460-57d7-11ea-8217-7ddc5e4b89b3/:userid/:suid",
    component: Survey11Component,
    canActivate: [AuthGuard],
  },
  {
    path: "AMSN/survey/amsn7460-57d7-11er-8217-7ddc5e4b89b4/:userid/:suid",
    component: Survey13Component,
    canActivate: [AuthGuard],
  },
  {
    path: "AMSN/survey/amsn2260-57d7-11er-8217-7ddc5e4b89b5/:userid/:suid",
    component: Survey14Component,
    canActivate: [AuthGuard],
  },
  {
    path: "DIY/survey/6acf092a-b744-4e7c-bc9f-f1d4fa27b291/:userid/:suid",
    component: Survey15Component,
    canActivate: [AuthGuard],
  },
  {
    path: "connektion-student/survey/fcd8dd36-57a7-448b-b310-3a60794d71fd/:userid/:suid",
    component: Survey16Component,
    canActivate: [AuthGuard],
  },
  {
    //Introduction For hrci-reunion-it-2021
    path: ":personal/hrci-reunion-it-2021/introduction/:id",
    component: IntroductionComponent,
    canActivate: [AuthGuard],
  },
  {
    //new url for hrci-reunion-it-2021
    path: "personal/hrci-reunion-it-2021/859becf9-7e4a-419f-8543-c13e07244d3f/:userid/:suid",
    component: Survey17Component,
    canActivate: [AuthGuard],
  },
  {
    //OESS diwali 2021
    path: "personal/OESS-Diwali-2021/9de67f7e-a3e5-4b3e-ba97-0ccbe1e16d4b/:userid/:suid",
    component: Survey18Component,
    canActivate: [AuthGuard],
  },
  {
    path: "surveyQuestions/:suid",
    component: SurveyQuestionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "AddSurvey",
    component: AddSurveyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "AddSurvey/:suid",
    component: AddSurveyComponent,
    canActivate: [AuthGuard],
  },
  { path: "thankyou", component: ThankyouComponent, canActivate: [AuthGuard] },
  { path: "expired", component: ExpiredComponent, canActivate: [AuthGuard] },
  {
    path: "pagenotfound",
    component: PagenotfoundComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "cookie-policy",
    component: CookiePolicyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "terms-of-use",
    component: TermsOfUseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: "login", component: LoginComponent, canActivate: [AuthGuard] },
  { path: "admin", component: AdminComponent, canActivate: [AuthGuard] },
  {
    path: "administrator",
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "**", redirectTo: "home" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    Globals,
    AuthGuard,
    SurveyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorClassService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppRoutingModule {}

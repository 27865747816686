import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

declare var $: any, PerfectScrollbar, swal: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  WebsiteName;
  
  constructor(public auth: AuthService) { }

  ngOnInit() {
    var scrolled = $(window).scrollTop();
    if (scrolled > 200) { $('.go_top').fadeIn('slow'); }
    if (scrolled < 200) { $('.go_top').fadeOut('slow'); }

    $('.go_top').click(function () {
      $("html, body").animate({ scrollTop: "0" }, 500);
    });   
    
  }

  ngAfterViewInit() {
    var currentYear = (new Date()).getFullYear();
    $("#footer_year").html(currentYear);
    // new PerfectScrollbar('.settings_tabs');
    // new PerfectScrollbar('.content_height');
    // new PerfectScrollbar('.assessment_preview_scroll');   
  }  

}

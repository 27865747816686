import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpInterceptorClassService } from "./http-interceptor-class.service";
import { WINDOW_PROVIDERS } from "./window.providers";

import { InputsModule } from "@progress/kendo-angular-inputs";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { PagerModule } from "@progress/kendo-angular-pager";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { PopupModule } from "@progress/kendo-angular-popup";
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { ExcelExportModule } from "@progress/kendo-angular-excel-export";
import { GridModule, PDFModule, ExcelModule } from "@progress/kendo-angular-grid";
import { TooltipModule } from "@progress/kendo-angular-tooltip";
import { UploadsModule } from "@progress/kendo-angular-upload";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { EditorModule } from "@progress/kendo-angular-editor";

import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./user/home/home.component";
import { IntroductionComponent } from "./user/introduction/introduction.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { CookiePolicyComponent } from "./cookie-policy/cookie-policy.component";
import { TermsOfUseComponent } from "./terms-of-use/terms-of-use.component";
import { ThankyouComponent } from "./user/thankyou/thankyou.component";
import { Survey1Component } from "./user/survey1/survey1.component";
import { Survey2Component } from "./user/survey2/survey2.component";
import { Survey3Component } from "./user/survey3/survey3.component";
import { Survey4Component } from "./user/survey4/survey4.component";
import { Survey5Component } from "./user/survey5/survey5.component";
import { Survey6Component } from "./user/survey6/survey6.component";
import { ExpiredComponent } from "./user/expired/expired.component";
import { Survey7Component } from "./user/survey7/survey7.component";
import { Survey8Component } from "./user/survey8/survey8.component";
import { Survey9Component } from "./user/survey9/survey9.component";
import { Survey10Component } from "./user/survey10/survey10.component";
import { Survey11Component } from "./user/survey11/survey11.component";
import { LoginComponent } from "./admin/login/login.component";
import { DashboardComponent } from "./admin/client-dashboard/client-dashboard.component";
import { Survey13Component } from "./user/survey13/survey13.component";
import { Survey14Component } from "./user/survey14/survey14.component";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { AdminComponent } from "./admin/admin.component";
import { AdminDashboardComponent } from "./admin/admin-dashboard/admin-dashboard.component";
import { RegistrationComponent } from "./admin/admin-dashboard/registration/registration.component";
import { surveysComponent } from "./admin/admin-dashboard/surveys/surveys.component";
import { SurveyQuestionsComponent } from "./admin/admin-dashboard/survey-questions/survey-questions.component";
import { OverviewComponent } from "./admin/client-dashboard/overview/overview.component";
import { ResponsesComponent } from "./admin/client-dashboard/responses/responses.component";
import { AddSurveyComponent } from "./admin/admin-dashboard/add-survey/add-survey.component";
import { Survey15Component } from "./user/survey15/survey15.component";
import { Survey16Component } from "./user/survey16/survey16.component";
import { Survey17Component } from "./user/survey17/survey17.component";
import { CookieService } from "ngx-cookie-service";
import { Survey18Component } from "./user/survey18/survey18.component";

@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    IntroductionComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    TermsOfUseComponent,
    ThankyouComponent,
    Survey1Component,
    Survey2Component,
    Survey3Component,
    Survey4Component,
    Survey5Component,
    Survey6Component,
    Survey7Component,
    ExpiredComponent,
    Survey8Component,
    Survey9Component,
    Survey10Component,
    Survey11Component,
    LoginComponent,
    DashboardComponent,
    Survey13Component,
    Survey14Component,
    Survey15Component,
    AdminComponent,
    AdminDashboardComponent,
    RegistrationComponent,
    AddSurveyComponent,
    surveysComponent,
    SurveyQuestionsComponent,
    OverviewComponent,
    ResponsesComponent,
    Survey16Component,
    Survey17Component,
    Survey18Component,
  ],
  imports: [
    BrowserModule,
    ExcelModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    InputsModule,
    DropDownsModule,
    PagerModule,
    DateInputsModule,
    ButtonsModule,
    PopupModule,
    LayoutModule,
    EditorModule,
    PDFExportModule,
    GridModule,
    PDFModule,
    ExcelModule,
    ExcelExportModule,
    TooltipModule,
    UploadsModule,
    DialogsModule,
  ],
  providers: [WINDOW_PROVIDERS, CookieService],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, OnInit, ViewChild } from "@angular/core";
import { Globals } from "../../globals";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { AutoCompleteComponent } from "@progress/kendo-angular-dropdowns";
import { SurveyService } from "../../services/survey.service";
declare var $, swal: any, Bloodhound: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  isError;
  surveyEntity;
  surveyList: any = [];
  newsurveyList;
  submitted;
  firstDivShow: boolean = true;
  survay;

  constructor(
    public globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private SurveyService: SurveyService
  ) {}

  ngOnInit() {
    this.newsurveyList = [];
    $(window).scrollTop(0);
    setTimeout(() => {
      const body = document.querySelector("body");
      body.style.setProperty("--screen-height", $(window).height() + "px");
    }, 100);
    this.isError = false;
    this.surveyEntity = {};
    this.SurveyService.getAllSurveys().then(
      (data) => {
        this.globals.isLoading = true;
        if (data) {
          this.surveyList = data;
          //filter surveys. not included survey 8,9,10,11
          //this.surveyList = this.surveyList.filter(data => data.SurveyId === "1" || data.SurveyId === "2" || data.SurveyId === "3" || data.SurveyId === "4" || data.SurveyId === "5" || data.SurveyId === "6" || data.SurveyId === "7");
          this.surveyList.splice(
            this.surveyList.findIndex((i) => i.SurveyUUIID == "atp47460-57d7-11ea-8217-7ddc5e4b89b3"),
            4
          ); //change every time while adding new survey

          for (let i = 0; i < this.surveyList.length; i++) {
            if (new Date(this.surveyList[i].StartDate) > new Date(this.globals.usaTime)) {
              this.surveyList[i].comingSoon = true;
              this.surveyList[i].isExpired = false;
            } else if (new Date(this.surveyList[i].ExpiryDate) < new Date(this.globals.usaTime)) {
              this.surveyList[i].isExpired = true;
              this.surveyList[i].comingSoon = false;
            } else {
              this.surveyList[i].isExpired = false;
              this.surveyList[i].comingSoon = false;
            }
          }
        }
        this.globals.isLoading = false;
      },
      (error) => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );
  }

  surveyClick(id) {
    //this.router.navigate([this.globals.surveyAbbreviation(id).name + '/introduction/' + id]);
    if (id === "859becf9-7e4a-419f-8543-c13e07244d3f") {
      window.open("/personal/hrci-reunion-it-2021/introduction/" + id, "_blank");
    } else {
      window.open(this.globals.surveyAbbreviation(id).name + "/introduction/" + id, "_blank");
    }
  }

  searchSurvey(searchForm) {
    debugger;
    this.submitted = true;
    if (searchForm.valid) {
      this.firstDivShow = false;
      this.submitted = false;

      // this.newsurveyList = this.surveyList.filter((s) =>
      //   s.SearchKeyWords.toLowerCase().indexOf(this.surveyEntity.SurveyName.toLowerCase()) !== -1);

      let newsurveyList = [];
      let SurveyName = $.trim(this.surveyEntity.SurveyName).split(" ");
      for (let i = 0; i < this.surveyList.length; i++) {
        let k = 0;
        let SearchKeyWords = $.trim(this.surveyList[i].SearchKeyWords.toLowerCase()).split(",");
        for (let j = 0; j < SurveyName.length; j++) {
          if ($.inArray(SurveyName[j].toLowerCase(), SearchKeyWords) !== -1) {
            //if(this.surveyList[i].SearchKeyWords.toLowerCase().indexOf(SurveyName[j].toLowerCase()) !== -1){
            k++;
          }
        }
        if (k > 0) {
          newsurveyList.push(this.surveyList[i]);
        }
      }
      this.newsurveyList = [...newsurveyList];
    }
  }
}
/* console.log(' ooooooooo   oooooooooo  oooooooooo  ooooooooo' + '\n' +
  'ooooooooooo  oooooooooo  oooooooooo  ooooooooo' + '\n' +
  'oooo   oooo  ooo         ooooo       oooo     ' + '\n' +
  'oooo   oooo  oooooooooo  ooooo       oooo     ' + '\n' +
  'oooo   oooo  oooooooooo  oooooooooo  ooooooooo' + '\n' +
  'oooo   oooo  ooo              ooooo       oooo' + '\n' +
  'ooooooooooo  oooooooooo       ooooo       oooo' + '\n' +
  ' ooooooooo   oooooooooo  oooooooooo  ooooooooo '); */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from "rxjs/operators";
import { Globals } from './globals';
declare var $, swal: any;

@Injectable()
export class HttpInterceptorClassService implements HttpInterceptor {
  constructor(private router: Router, private globals: Globals) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.indexOf('https://ifsc.razorpay.com') === 0) {
      req = req.clone({
        url: req.url.replace("http://", "https://")
      });
      return next.handle(req); // do nothing      
    } else {
      // req = req.clone({
      //   setHeaders: {
      //     Authorization: `Bearer ${localStorage.getItem('token')}`
      //   }
      // });
    }

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.body.error_code == 2008) {
          return this.router.navigate(['/pagenotfound']);
        } else {
          return next.handle(req);
        }
      }
    }, (err: any) => { debugger
      if (err instanceof HttpErrorResponse) {
        if (err.status === 406) {
          // redirect to the login route
          // or show a modal
        }
        return next.handle(req);
      }
    }));

  }
} 

import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.css']
})
export class CookiePolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(window).scrollTop(0);
  }

}

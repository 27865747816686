import { Component, OnInit, ViewChild } from "@angular/core";
import { Globals } from "../../globals";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { SurveyService } from "../../services/survey.service";
declare var $, swal: any, Bloodhound: any;

@Component({
  selector: "app-survey17",
  templateUrl: "./survey17.component.html",
  styleUrls: ["./survey17.component.css"],
})
export class Survey17Component implements OnInit {
  userEntity: any = {};
  submitted: boolean = false;
  questionEntity: any = {};
  slider1: boolean = true;
  checkDisabled: boolean = true;
  showAdditionalFeedback: boolean = false;
  userEntityDisable: boolean = false;
  surveyDetails;
  id;
  regions;
  pays;
  quality;
  importants;
  tests;
  stepArr;
  communicateArr;
  checkbox_selected: number = 0;
  totalQuestions: number = 8;
  questionList: any = [
    { QuestionId: 284, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 285, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 286, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 287, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 288, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 289, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 290, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 291, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 292, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 293, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 294, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 295, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 296, SurveyAnswerId: 0, AnswerText: null, Comment: null },
  ];
  kidsArray: any;
  kidsAge: any[] = ["less than one", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];

  constructor(
    public globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private SurveyService: SurveyService
  ) {}

  ngOnInit() {
    $(window).scrollTop(0);
    setTimeout(() => {
      const body = document.querySelector("body");
      body.style.setProperty("--screen-height", $(window).height() + "px");
    }, 100);

    $("#progress_circle")
      .circleProgress({
        value: 0,
        size: 70.0,
        emptyFill: "#ccc",
        fill: { gradient: ["#033F6F", "#033F6F"] },
      })
      .on("circle-animation-progress", function (event, progress) {
        $(this).find("strong").html("0%");
      });

    this.userEntity.UserId = 0;
    this.userEntity.SurveyUserId = this.route.snapshot.paramMap.get("suid");
    this.userEntity.SurveyId = 17;
    this.userEntity.IsAnonymous = true;

    this.id = this.route.snapshot.url[2].path;
    this.SurveyService.getSurveyDetails(this.id).then(
      (data) => {
        this.surveyDetails = data;
        if (new Date(this.surveyDetails.ExpiryDate) >= new Date(this.globals.usaTime)) {
          this.globals.title.setTitle(
            "Workforce Skills Survey for Credentials Issuers by Association for Testing Professionals"
          );
          this.globals.logo = data["Logo"];
          this.globals.logoURL = data["LogoURL"];
        } else {
          this.router.navigate(["/expired"]);
        }
        this.globals.isLoading = false;
      },
      (error) => {
        this.globals.isLoading = false;
        this.router.navigate(["/pagenotfound"]);
      }
    );
  }

  AnonymousChange() {
    if (!this.userEntity.IsAnonymous) {
      this.userEntityDisable = true;
      this.userEntity.FirstName = null;
      this.userEntity.LastName = null;
      this.userEntity.EmailAddress = null;
      this.userEntity.PhoneNumber = null;
      this.userEntity.Company = null;
    } else {
      this.userEntityDisable = false;
    }
  }

  startSurvey(userForm) {
    this.submitted = true;
    if (userForm.valid) {
      this.globals.isLoading = true;
      this.SurveyService.startSurvey(this.userEntity).then(
        (data) => {
          this.userEntity.UserId = data["UserId"];
          this.userEntity.SurveyUserId = data["SurveyUserId"];
          this.questionEntity.SurveyUserId = data["SurveyUserId"];
          this.globals.isLoading = false;
          this.slider1 = true;
          this.submitted = false;
          //console.log(this.questionEntity);
        },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      );
    }
  }

  GetDropdownValue(questionId, value) {
    if (value == 0 || value == "0") {
      this.dropdownChange(questionId, value);
      this.kidsArray = [];
      this.questionList[this.questionList.findIndex((x) => x.QuestionId === 289)].AnswerText
        ? this.submitAnswer(289, null, null)
        : "";
      this.questionList[this.questionList.findIndex((x) => x.QuestionId === 290)].AnswerText
        ? this.submitAnswer(290, null, null)
        : "";
      this.questionList[this.questionList.findIndex((x) => x.QuestionId === 291)].AnswerText
        ? this.submitAnswer(291, null, null)
        : "";
      this.questionList[this.questionList.findIndex((x) => x.QuestionId === 292)].AnswerText
        ? this.submitAnswer(292, null, null)
        : "";
      this.questionList[this.questionList.findIndex((x) => x.QuestionId === 293)].AnswerText
        ? this.submitAnswer(293, null, null)
        : "";
    } else {
      this.kidsArray = Array(value);
      this.dropdownChange(questionId, value);
    }
  }
  dropdownChange(questionId, value) {
    this.submitAnswer(questionId, value, null);
  }

  submitSurveywithUser(userForm) {
    debugger;
    this.submitted = true;
    if (userForm.valid) {
      $("#Preview_Modal").modal("hide");
      this.globals.isLoading = true;
      let postData = this.userEntity;
      postData.IsAnonymous = false;
      postData.SurveyStatus = this.userEntity.SurveyStatus;
      this.SurveyService.submitSurveywithUser(postData).then(
        (data) => {
          this.globals.isLoading = false;
          this.router.navigate(["/thankyou"]);
        },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      );
    }
  }

  submitSurvey(SurveyStatus) {
    this.userEntity.SurveyStatus = SurveyStatus;
    this.globals.surveyId = 17;
    if (this.userEntity.IsAnonymous) {
      swal({
        type: this.globals.commonTranslationText.common.alerts.confirmSubmit.type,
        title: this.globals.commonTranslationText.common.alerts.confirmSubmit.title,
        text: this.globals.commonTranslationText.common.alerts.confirmSubmit.text,
        //icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          let postData = {
            SurveyUserId: this.userEntity.SurveyUserId,
            SurveyStatus: SurveyStatus,
            IsAnonymous: this.userEntity.IsAnonymous,
            EmailAddress: this.userEntity.EmailAddress,
            SurveyId: this.userEntity.SurveyId,
            UserId: this.userEntity.UserId,
          };
          this.SurveyService.submitSurvey(postData).then(
            (data) => {
              this.globals.isLoading = false;
              this.router.navigate(["/thankyou"]);
            },
            (error) => {
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000,
              });
            }
          );
        } else {
          $("#Preview_Modal").modal("show");
        }
      });
    } else {
      swal({
        type: this.globals.commonTranslationText.common.alerts.confirmSubmit.type,
        title: this.globals.commonTranslationText.common.alerts.confirmSubmit.title,
        text: this.globals.commonTranslationText.common.alerts.confirmSubmit.text,
        //icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          let postData = {
            SurveyUserId: this.userEntity.SurveyUserId,
            SurveyStatus: SurveyStatus,
            IsAnonymous: this.userEntity.IsAnonymous,
            EmailAddress: this.userEntity.EmailAddress,
            SurveyId: this.userEntity.SurveyId,
            UserId: this.userEntity.UserId,
          };
          this.SurveyService.submitSurvey(postData).then(
            (data) => {
              this.globals.isLoading = false;
              this.router.navigate(["/thankyou"]);
            },
            (error) => {
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000,
              });
            }
          );
        }
      });
    }
  }

  checkAdditionalFeedback(QuestionId, AnswerText, Comment) {
    if (AnswerText == "Yes") {
      this.showAdditionalFeedback = true;
    } else {
      this.showAdditionalFeedback = false;
    }
    this.submitAnswer(QuestionId, AnswerText, Comment);
  }

  toggleChange(QuestionId, AnswerText, Comment) {
    if (AnswerText) {
      AnswerText = "Yes";
    } else {
      AnswerText = "No";
    }
    this.submitAnswer(QuestionId, AnswerText, Comment);
  }

  submitAnswer(QuestionId, AnswerText, Comment) {
    var index = this.questionList.findIndex((x) => x.QuestionId === QuestionId);
    let postData = {
      SurveyUserId: this.userEntity.SurveyUserId,
      SurveyAnswerId: this.questionList[index].SurveyAnswerId,
      QuestionId: QuestionId,
      AnswerText: AnswerText,
      Comment: Comment,
    };

    this.SurveyService.submitAnswer(postData).then(
      (data) => {
        this.questionList[index].SurveyAnswerId = data["SurveyAnswerId"];
        this.questionList[index].AnswerText = $.trim(AnswerText);
        this.questionList[index].Comment = $.trim(Comment);
        // this.checkprogress();
      },
      (error) => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );
  }

  checkprogress() {
    let k = 0;
    let forlooplength = 0;
    this.totalQuestions = 22;
    forlooplength = 21;
    for (let i = 0; i <= forlooplength; i++) {
      if (this.questionList[i].QuestionId > 0) {
        if (
          this.questionList[i].SurveyAnswerId > 0 &&
          this.questionList[i].AnswerText != null &&
          this.questionList[i].AnswerText != ""
        ) {
          k++;
        }
      }
    }
    let addpro = (100 * k) / this.totalQuestions;
    if (addpro > 0) {
      this.checkDisabled = false;
    } else {
      this.checkDisabled = true;
    }
    let percent = Math.round(addpro);
    $("#progress_circle")
      .circleProgress({
        value: k / this.totalQuestions,
        size: 70.0,
        emptyFill: "#ccc",
        fill: { gradient: ["#033F6F", "#033F6F"] },
      })
      .on("circle-animation-progress", function (event, progress) {
        $(this)
          .find("strong")
          .html(percent + "%");
      });
  }
}

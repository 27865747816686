import { Component, OnInit, SecurityContext } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { SurveyService } from "../../services/survey.service";
import { Globals } from "../../globals";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { GroupDescriptor, process, SortDescriptor } from "@progress/kendo-data-query";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { formatDate } from "@angular/common";
import { JwtHelperService } from "@auth0/angular-jwt";
import { PageChangeEvent } from "@progress/kendo-angular-pager";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { CookieService } from "ngx-cookie-service";

declare var $: any, swal;

@Component({
  selector: "app-client-dashboard",
  templateUrl: "./client-dashboard.component.html",
  styleUrls: ["./client-dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  public now: Date = new Date();
  loginForm: FormGroup;
  userLogin: any;
  errorMsg: boolean = false;
  surveyList: any = [];
  selectedSurvey: any;
  surveyResponses: any;
  surveyOverview: any;
  surveyQuestion: number;
  surveyResponse: number;
  uniqueFeedback: number;
  totalComments: number;
  isName: boolean = false;
  filename: any;
  TotalSurveys: number = 0;
  OpenSurveys: number = 0;
  ExpiredSurveys: number = 0;
  usaTime = new Date(this.globals.usaTime);
  public selectedValue;

  public gridView: GridDataResult;
  public pageSizes: any = [
    10,
    25,
    50,
    100,
    {
      text: "All",
      value: "all",
    },
  ];
  public pageSize = 10;
  public skip = 0;
  private data: Object[];
  public groups: GroupDescriptor[];
  public allowUnsort = true;

  public PagerSkip = 0;
  public PagerPageSize = 5;
  public pagedArticles = [];
  public PagerTotal;
  public prevNext = true;
  public type = "numeric";
  pagedsurveyList: any;
  iFrameUrl: SafeResourceUrl;

  constructor(
    private globals: Globals,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private SurveyService: SurveyService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cookieService: CookieService
  ) {
    if (!this.auth.isLoggedIn()) {
      router.navigate(["login"]);
    } else {
      let jwtHelper = new JwtHelperService();
      const decodedToken = jwtHelper.decodeToken(this.cookieService.get("token"));
      decodedToken.Role == 1 ? this.router.navigate(["administrator"]) : this.router.navigate(["dashboard"]);
      this.userLogin = decodedToken.SurveyKeyword;
      this.globals.userLogin = true;
      this.allData = this.allData.bind(this);
      // http
      //   .get("https://www.google.com/")
      //   .map((html: any) => (this.myTemplate = html));

      this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://survey-v2.devbyopeneyes.com/");
    }
  }

  ngOnInit() {
    this.getAllSurveys();

    // var div = document.getElementById("target") as HTMLIFrameElement;
    // div.onload = function () {
    //   div.style.height = div.contentWindow.document.body.scrollHeight + "px";
    // };
  }

  resizeIframe() {
    var div = document.getElementById("analytics") as HTMLIFrameElement;
    var div1 = document.getElementById("analyticsGrid");
    div.style.height = div.contentWindow.document.body.scrollHeight + "px";
    div1.style.height = div.contentWindow.document.body.scrollHeight + "px";
  }

  //get all survay list based on login credentials (filter by search keyword in database)
  getAllSurveys() {
    this.SurveyService.getAllSurveys().then(
      (data) => {
        if (data) {
          var d: any = data;
          //remove data of other users
          this.surveyList = d.filter((s) => new RegExp(this.userLogin).test(s.SearchKeyWords));
          //remove data of survey 12
          if (this.userLogin == "ATP") {
            this.surveyList.splice(
              this.surveyList.findIndex((i) => i.SurveyUUIID == "atp57460-57d7-11ea-8217-7ddc5e4b89b3"),
              1
            );
          }

          this.TotalSurveys = this.surveyList.length;
          //update date from string to date time object
          for (var v in this.surveyList) {
            this.surveyList[v]["ExpiryDate"] = new Date(this.surveyList[v]["ExpiryDate"]);
          }

          this.PagerPageData();
          for (let i = 0; i < this.surveyList.length; i++) {
            if (this.surveyList[i].ExpiryDate > this.usaTime) {
              this.OpenSurveys++;
            } else {
              this.ExpiredSurveys++;
            }
            this.SurveyService.SurveyStatistics({
              SurveyId: this.surveyList[i].SurveyId,
            }).then((data) => {
              if (data["message"] != "Something is wrong!") {
                this.errorMsg = false;

                //count total survey Response
                this.surveyList[i]["surveyResponse"] = data["surveyResponse"][0]["Response"];

                // count total survey Question
                this.surveyList[i]["surveyQuestion"] = data["TotalUniqueQuestions"][0]["Questions"];

                // count unique users
                this.surveyList[i]["uniqueFeedback"] = data["TotalUniqueUser"][0]["Users"];
              } else {
                this.errorMsg = true;
              }
              this.globals.isLoading = false;
            });
          }
        }
        this.globals.isLoading = false;
      },
      (error) => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );
  }

  surveyDropdown(i) {
    this.globals.isLoading = true;
    this.selectedSurvey = i;
    this.selectedValue = this.selectedSurvey;

    this.SurveyService.surveyByDate({
      SurveyUserId: this.selectedSurvey.SurveyId,
    }).then(
      (data) => {
        if (data["message"] != "Something is wrong!") {
          this.surveyOverview = data[0];
        } else {
          // this.errorMsg = true;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: "No survey response found!",
            showConfirmButton: false,
            timer: 4000,
          });
        }
      },
      (error) => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );

    this.filename = this.selectedSurvey.SurveyName.replace(/(<([^>]+)>)/gi, "");
    this.groups = [];
    this.sort = [
      {
        field: "UpdatedOn",
        dir: "desc",
      },
    ];
    this.skip = 0;

    this.downloadSurvey(this.selectedSurvey.SurveyId);
  }

  downloadSurvey(id) {
    //get total card data
    this.SurveyService.SurveyStatistics({ SurveyId: id }).then((data) => {
      if (data["message"] != "Something is wrong!") {
        this.errorMsg = false;

        //count total survey Response
        this.surveyResponse = data["surveyResponse"][0]["Response"];

        // count total survey Question
        this.surveyQuestion = data["TotalUniqueQuestions"][0]["Questions"];

        // count unique users
        this.uniqueFeedback = data["TotalUniqueUser"][0]["Users"];
      } else {
        this.errorMsg = true;
      }
    });

    this.SurveyService.downloadSurvey({ SurveyUserId: id }).then(
      (data) => {
        if (data["message"] != "Something is wrong!") {
          this.errorMsg = false;

          this.surveyResponses = data[0];

          /* Remove empty responses */
          this.surveyResponses = this.surveyResponses.filter(function (el) {
            return el["AnswerText"] !== "";
          });

          //count total survey Response
          this.surveyResponse = this.surveyResponses.length;

          //create a group by unique user ids.
          const listOfIds = [...new Set(this.surveyResponses.map((it) => it.UserId))];
          //update user id from 1,2,3... for perticular survey and convert date into specific format
          for (var val in listOfIds) {
            //change date formate
            this.surveyResponses = this.surveyResponses.map((q) =>
              q.UserId !== listOfIds[val]
                ? q
                : {
                    ...q,
                    UpdatedOn: formatDate(q.UpdatedOn, "yyyy/MM/dd h:mm a", "en-US"),
                  }
            );
            //change response id start from 1
            this.surveyResponses = this.surveyResponses.map((p) =>
              p.UserId !== listOfIds[val] ? p : { ...p, UserId: Number(val) + 1 }
            );
          }
          // ------------------ for question -------------------------------------------------------------
          //create a group by question user ids.
          const listOfQIds = [...new Set(this.surveyResponses.map((it) => it.QuestionId))].sort();
          //update user id from 1,2,3... for particular survey and convert date into specific format
          for (var val in listOfQIds) {
            //change response id start from 1
            this.surveyResponses = this.surveyResponses.map((p) =>
              p.QuestionId !== listOfQIds[val] ? p : { ...p, QuestionId: Number(val) + 1 }
            );
          }
          const listOfOptionIds = [...new Set(this.surveyResponses.map((it) => it.AnswerText))];
          for (var res in listOfOptionIds) {
            //change response id start from 1
            this.surveyResponses = this.surveyResponses.map((r) =>
              r.AnswerText !== listOfOptionIds[res] ? r : { ...r, SurveyAnswerId: Number(res) + 1 }
            );
          }
          // console.log(listOfOptionIds);
          // console.log(listOfQIds);
          // console.log(this.surveyResponses);

          //--------------- remove ,, from answer text ---------------------------------------------------
          for (var v in this.surveyResponses) {
            this.surveyResponses[v]["AnswerText"] = this.surveyResponses[v]["AnswerText"].replace(/,,/gi, "||");
          }

          //count total survey Response
          // this.surveyResponse = data[0].length;

          // count total survey Question
          //   this.surveyQuestion = [...new Set(this.surveyResponses.map(it => it.QuestionId))].length;

          // count unique users
          //  this.uniqueFeedback = [...new Set(this.surveyResponses.map(it => it.UserId))].length;

          // count total comments
          this.totalComments = [...new Set(this.surveyResponses.map((it) => it.Comment))].length;

          // false if survay have all anonymous names / true if survay have different name entrys
          this.isName = [...new Set(this.surveyResponses)].length == this.surveyResponse ? false : true;

          //load data to first page as per page size

          this.surveyResponses.sort(this.sortData);
          this.loadItems();
        } else {
          this.surveyResponses = [];
          this.gridView = null;
          this.errorMsg = true;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      },
      (error) => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );
    this.globals.isLoading = false;
  }

  bachToHome() {
    this.gridView = null;
  }

  analytics() {
    // console.table(this.selectedValue);
    let url = this.globals.analyticsUrl + "Home/" + this.selectedValue.SurveyId;
    window.open(url, "_blank");
  }

  //-------------------------------------------- for Kendo Data Pager

  public PagerPageData(): void {
    this.PagerTotal = this.surveyList.length;
    this.pagedsurveyList = this.surveyList.slice(this.PagerSkip, this.PagerSkip + this.PagerPageSize);
  }

  public PagerOnPageChange(e: PageChangeEvent): void {
    this.PagerSkip = e.skip;
    this.PagerPageSize = e.take;
    this.PagerPageData();
  }

  //-------------------------------------------- for All records

  sortData(a, b) {
    // const valueA = a.UpdatedOn.toUpperCase();
    const valueA = a.UpdatedOn;
    const valueB = b.UpdatedOn;
    let comparison = 0;
    if (valueA < valueB) {
      comparison = 1;
    } else if (valueA > valueB) {
      comparison = -1;
    }
    return comparison;
  }

  public pageChange(event: PageChangeEvent): void {
    // change page
    this.skip = event.skip;
    this.pageSize = event.take;
    this.loadItems();
    this.loadProducts();
  }

  private loadItems(): void {
    // load data bases on page
    //this.globals.isLoading = true;
    this.gridView = {
      data: this.surveyResponses.slice(this.skip, this.skip + this.pageSize),
      total: this.surveyResponses.length,
    };
    //this.globals.isLoading = false;
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.loadItems();
    this.loadProducts();
  }

  private loadProducts(): void {
    // create groups
    this.gridView = process(this.gridView.data, {
      group: this.groups,
      sort: this.sort,
    });
    this.gridView.total = this.surveyResponses.length;
  }

  //get all data to export in excel file
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.surveyResponses, {
        group: this.groups,
        sort: this.sort,
      }).data,
      group: this.groups,
    };
    return result;
  }

  //default sort
  public sort: SortDescriptor[] = [
    {
      field: "UpdatedOn",
      dir: "desc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.gridView = process(this.surveyResponses, {
      group: this.groups,
      sort: this.sort,
    });
    this.gridView.total = this.surveyResponses.length;
    this.surveyResponses = this.gridView.data;
    this.surveyResponses.total = this.surveyResponses.length;
    //this.loadItems();
    this.gridView = {
      data: this.gridView.data.slice(this.skip, this.skip + this.pageSize),
      total: this.gridView.total,
    };
    this.loadProducts();
  }

  public shouldHide(fieldName) {
    // hide columns which is in group
    return this.groups.some((g) => g.field === fieldName);
  }
}

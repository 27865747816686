import { Component, OnInit, ViewChild } from "@angular/core";
import { Globals } from "../../globals";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { SurveyService } from "../../services/survey.service";
declare var $, swal: any, Bloodhound: any;

@Component({
  selector: "app-survey15",
  templateUrl: "./survey15.component.html",
  styleUrls: ["./survey15.component.css"],
})
export class Survey15Component implements OnInit {
  userEntity: any = {};
  submitted: boolean = false;
  questionEntity: any = {};
  slider1: boolean = true;
  slider2: boolean = false;
  slider3: boolean = false;
  checkDisabled: boolean = true;
  showAdditionalFeedback: boolean = false;
  userEntityDisable: boolean = false;
  countryList;
  usStateList;
  surveyDetails;
  id;
  educations;
  pays;
  quality;
  importants;
  occupations: any;
  stepArr;
  communicateArr;
  checkbox_selected: number = 0;
  totalQuestions: number = 25;
  questionList: any = [
    { QuestionId: 225, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 226, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 227, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 228, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 229, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 230, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 231, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 232, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 233, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 234, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 235, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 236, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 237, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 238, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 239, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 240, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 241, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 242, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 243, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 244, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 245, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 246, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 247, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 248, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 249, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 250, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 251, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 252, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 253, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 254, SurveyAnswerId: 0, AnswerText: null, Comment: null },
    { QuestionId: 255, SurveyAnswerId: 0, AnswerText: null, Comment: null },
  ];

  constructor(
    public globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private SurveyService: SurveyService
  ) {}

  ngOnInit() {
    $(window).scrollTop(0);
    setTimeout(() => {
      const body = document.querySelector("body");
      body.style.setProperty("--screen-height", $(window).height() + "px");
    }, 100);

    $("#progress_circle")
      .circleProgress({
        value: 0,
        size: 70.0,
        emptyFill: "#ccc",
        fill: { gradient: ["#033F6F", "#033F6F"] },
      })
      .on("circle-animation-progress", function (event, progress) {
        $(this).find("strong").html("0%");
      });

    this.userEntity.UserId = 0;
    this.userEntity.SurveyUserId = this.route.snapshot.paramMap.get("suid");
    this.userEntity.SurveyId = 15;
    this.userEntity.IsAnonymous = true;

    this.id = this.route.snapshot.url[2].path;
    this.SurveyService.getSurveyDetails(this.id).then(
      (data) => {
        this.surveyDetails = data;
        if (
          new Date(this.surveyDetails.ExpiryDate) >=
          new Date(this.globals.usaTime)
        ) {
          this.globals.title.setTitle("DIY Product Survey");
          this.globals.logo = data["Logo"];
          this.globals.logoURL = data["LogoURL"];
        } else {
          this.router.navigate(["/expired"]);
        }
        this.globals.isLoading = false;
      },
      (error) => {
        this.globals.isLoading = false;
        this.router.navigate(["/pagenotfound"]);
      }
    );
  }

  AnonymousChange() {
    if (!this.userEntity.IsAnonymous) {
      this.userEntityDisable = true;
      this.userEntity.FirstName = null;
      this.userEntity.LastName = null;
      this.userEntity.EmailAddress = null;
      this.userEntity.PhoneNumber = null;
      this.userEntity.Company = null;
    } else {
      this.userEntityDisable = false;
    }
  }

  startSurvey(userForm) {
    this.submitted = true;
    if (userForm.valid) {
      this.globals.isLoading = true;
      this.SurveyService.startSurvey(this.userEntity).then(
        (data) => {
          this.userEntity.UserId = data["UserId"];
          this.userEntity.SurveyUserId = data["SurveyUserId"];
          this.questionEntity.SurveyUserId = data["SurveyUserId"];
          this.globals.isLoading = false;
          this.slider1 = false;
          this.slider2 = true;
          this.submitted = false;
          //console.log(this.questionEntity);
        },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts
              .somethingWrong.type,
            title:
              this.globals.commonTranslationText.common.alerts.somethingWrong
                .title,
            text: this.globals.commonTranslationText.common.alerts
              .somethingWrong.text,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      );
    }
  }

  changeCheckbox(QuestionId, idno) {
    if ($("#q10" + idno).prop("checked")) {
      var AnswerText_arr = new Array();
      $("input[name='ownership_of_Goods']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      this.quality = AnswerText;
      this.submitAnswer(QuestionId, AnswerText, null);
    } else {
      var AnswerText_arr = new Array();
      $("input[name='ownership_of_Goods']:checked").each(function () {
        AnswerText_arr.push($(this).val());
      });
      this.checkbox_selected = AnswerText_arr.length;
      let AnswerText = AnswerText_arr.join(",,");
      this.submitAnswer(QuestionId, AnswerText, null);
    }
  }

  submitSurveywithUser(userForm) {
    debugger;
    this.submitted = true;
    if (userForm.valid) {
      $("#Preview_Modal").modal("hide");
      this.globals.isLoading = true;
      let postData = this.userEntity;
      postData.IsAnonymous = false;
      postData.SurveyStatus = this.userEntity.SurveyStatus;
      this.SurveyService.submitSurveywithUser(postData).then(
        (data) => {
          this.globals.isLoading = false;
          this.router.navigate(["/thankyou"]);
        },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts
              .somethingWrong.type,
            title:
              this.globals.commonTranslationText.common.alerts.somethingWrong
                .title,
            text: this.globals.commonTranslationText.common.alerts
              .somethingWrong.text,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      );
    }
  }

  submitSurvey(SurveyStatus) {
    this.userEntity.SurveyStatus = SurveyStatus;
    this.globals.surveyId = 15;
    if (this.userEntity.IsAnonymous) {
      swal({
        type: this.globals.commonTranslationText.common.alerts.confirmSubmit
          .type,
        title:
          this.globals.commonTranslationText.common.alerts.confirmSubmit.title,
        text: this.globals.commonTranslationText.common.alerts.confirmSubmit
          .text,
        //icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          let postData = {
            SurveyUserId: this.userEntity.SurveyUserId,
            SurveyStatus: SurveyStatus,
            IsAnonymous: this.userEntity.IsAnonymous,
            EmailAddress: this.userEntity.EmailAddress,
            SurveyId: this.userEntity.SurveyId,
            UserId: this.userEntity.UserId,
          };
          this.SurveyService.submitSurvey(postData).then(
            (data) => {
              this.globals.isLoading = false;
              this.router.navigate(["/thankyou"]);
            },
            (error) => {
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts
                  .somethingWrong.type,
                title:
                  this.globals.commonTranslationText.common.alerts
                    .somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts
                  .somethingWrong.text,
                showConfirmButton: false,
                timer: 4000,
              });
            }
          );
        } else {
          $("#Preview_Modal").modal("show");
        }
      });
    } else {
      swal({
        type: this.globals.commonTranslationText.common.alerts.confirmSubmit
          .type,
        title:
          this.globals.commonTranslationText.common.alerts.confirmSubmit.title,
        text: this.globals.commonTranslationText.common.alerts.confirmSubmit
          .text,
        //icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.globals.isLoading = true;
          let postData = {
            SurveyUserId: this.userEntity.SurveyUserId,
            SurveyStatus: SurveyStatus,
            IsAnonymous: this.userEntity.IsAnonymous,
            EmailAddress: this.userEntity.EmailAddress,
            SurveyId: this.userEntity.SurveyId,
            UserId: this.userEntity.UserId,
          };
          this.SurveyService.submitSurvey(postData).then(
            (data) => {
              this.globals.isLoading = false;
              this.router.navigate(["/thankyou"]);
            },
            (error) => {
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts
                  .somethingWrong.type,
                title:
                  this.globals.commonTranslationText.common.alerts
                    .somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts
                  .somethingWrong.text,
                showConfirmButton: false,
                timer: 4000,
              });
            }
          );
        }
      });
    }
  }

  checkAdditionalFeedback(QuestionId, AnswerText, Comment) {
    if (AnswerText == "Yes") {
      this.showAdditionalFeedback = true;
    } else {
      this.showAdditionalFeedback = false;
    }
    this.submitAnswer(QuestionId, AnswerText, Comment);
  }

  toggleChange(QuestionId, AnswerText, Comment) {
    if (AnswerText) {
      AnswerText = "Yes";
    } else {
      AnswerText = "No";
    }
    this.submitAnswer(QuestionId, AnswerText, Comment);
  }

  submitAnswer(QuestionId, AnswerText, Comment) {
    var index = this.questionList.findIndex((x) => x.QuestionId === QuestionId);
    let postData = {
      SurveyUserId: this.userEntity.SurveyUserId,
      SurveyAnswerId: this.questionList[index].SurveyAnswerId,
      QuestionId: QuestionId,
      AnswerText: AnswerText,
      Comment: Comment,
    };

    this.SurveyService.submitAnswer(postData).then(
      (data) => {
        this.questionList[index].SurveyAnswerId = data["SurveyAnswerId"];
        this.questionList[index].AnswerText = $.trim(AnswerText);
        this.questionList[index].Comment = Comment;
        this.checkprogress();
      },
      (error) => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong
            .type,
          title:
            this.globals.commonTranslationText.common.alerts.somethingWrong
              .title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong
            .text,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );

    if (QuestionId == 235) {
      // if (
      //   this.questionList[
      //     this.questionList.findIndex((x) => x.QuestionId === 236)
      //   ].AnswerText
      // ) {
      //   this.submitAnswer(236, null, null);
      // }
      var que = 12;
      while (QuestionId < 243) {
        QuestionId++;
        this.questionEntity["q" + que] = null;
        if (
          this.questionList[
            this.questionList.findIndex((x) => x.QuestionId === QuestionId)
          ].AnswerText
        ) {
          this.submitAnswer(QuestionId, null, null);
        }
        que++;
      }
    }
    if (QuestionId == 236 || QuestionId == 237) {
      var que = 14;
      while (QuestionId < 243) {
        QuestionId++;
        this.questionEntity["q" + que] = null;
        if (
          this.questionList[
            this.questionList.findIndex((x) => x.QuestionId === QuestionId)
          ].AnswerText
        ) {
          this.submitAnswer(QuestionId, null, null);
        }
        que++;
      }
    }
    if (QuestionId == 238) {
      var que = 15;
      while (QuestionId < 243) {
        QuestionId++;
        this.questionEntity["q" + que] = null;
        if (
          this.questionList[
            this.questionList.findIndex((x) => x.QuestionId === QuestionId)
          ].AnswerText
        ) {
          this.submitAnswer(QuestionId, null, null);
        }
        que++;
      }
    }
    if (QuestionId == 241) {
      var que = 18;
      while (QuestionId < 243) {
        QuestionId++;
        this.questionEntity["q" + que] = null;
        if (
          this.questionList[
            this.questionList.findIndex((x) => x.QuestionId === QuestionId)
          ].AnswerText
        ) {
          this.submitAnswer(QuestionId, null, null);
        }
        que++;
      }
    }
    if (QuestionId == 244 || QuestionId == 245) {
      var que = 22;
      while (QuestionId < 255) {
        QuestionId++;
        this.questionEntity["q" + que] = null;
        if (
          this.questionList[
            this.questionList.findIndex((x) => x.QuestionId === QuestionId)
          ].AnswerText
        ) {
          this.submitAnswer(QuestionId, null, null);
        }
        que++;
      }
    }
    if (QuestionId == 246 || QuestionId == 247) {
      var que = 24;
      while (QuestionId < 255) {
        QuestionId++;
        this.questionEntity["q" + que] = null;
        if (
          this.questionList[
            this.questionList.findIndex((x) => x.QuestionId === QuestionId)
          ].AnswerText
        ) {
          this.submitAnswer(QuestionId, null, null);
        }
        que++;
      }
    }
    if (QuestionId == 250 || QuestionId == 251) {
      var que = 28;
      while (QuestionId < 255) {
        QuestionId++;
        this.questionEntity["q" + que] = null;
        if (
          this.questionList[
            this.questionList.findIndex((x) => x.QuestionId === QuestionId)
          ].AnswerText
        ) {
          this.submitAnswer(QuestionId, null, null);
        }
        que++;
      }
    }
  }

  checkprogress() {
    let k = 0;
    let forlooplength = 0;
    this.totalQuestions = 22;
    forlooplength = 21;
    for (let i = 0; i <= forlooplength; i++) {
      if (this.questionList[i].QuestionId > 0) {
        if (
          this.questionList[i].SurveyAnswerId > 0 &&
          this.questionList[i].AnswerText != null &&
          this.questionList[i].AnswerText != ""
        ) {
          k++;
        }
      }
    }
    let addpro = (100 * k) / this.totalQuestions;
    if (addpro > 0) {
      this.checkDisabled = false;
    } else {
      this.checkDisabled = true;
    }
    let percent = Math.round(addpro);
    $("#progress_circle")
      .circleProgress({
        value: k / this.totalQuestions,
        size: 70.0,
        emptyFill: "#ccc",
        fill: { gradient: ["#033F6F", "#033F6F"] },
      })
      .on("circle-animation-progress", function (event, progress) {
        $(this)
          .find("strong")
          .html(percent + "%");
      });
  }

  next1() {
    this.slider2 = true;
    this.slider1 = false;
    $(window).scrollTop(0);
  }

  previous2() {
    this.slider2 = false;
    this.slider1 = true;
    $(window).scrollTop(0);

    /* code for check box check issue  Insert QuestionId from array */
    let i = 1;
    var index = this.questionList.findIndex((x) => x.QuestionId === 234);
    if (this.questionList[index].AnswerText != null) {
      let answer_arr = this.questionList[index].AnswerText.split(",,");
      setTimeout(function () {
        $("input[name='ownership_of_Goods']").each(function () {
          if ($.inArray($(this).val(), answer_arr) !== -1) {
            $("#q10" + i).prop("checked", true);
          } else {
            $("#q10" + i).prop("checked", false);
          }
          i++;
        });
      }, 100);
    }
    /* checkboc check end */
  }

  next2() {
    this.slider2 = false;
    this.slider3 = true;
    $(window).scrollTop(0);
  }

  previous3() {
    this.slider3 = false;
    this.slider2 = true;
    $(window).scrollTop(0);
  }
}

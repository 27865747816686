import { Observable } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Globals } from 'src/app/globals';
import { SurveyService } from '../../../services/survey.service';
import { Router } from '@angular/router';
declare var $: any, swal;

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class surveysComponent implements OnInit {

  @Input('decodedToken') decodedToken;
  @Output('overview') overview = new EventEmitter();

  usaTime = new Date(this.globals.usaTime);
  userId: number;
  allsurveys: any;
  public view: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10
  };

  constructor(private globals: Globals, private survey: SurveyService, private router: Router) { }

  ngOnInit() {
    this.userId = this.decodedToken['AdminUserId'];
    this.getAllSurveys();
  }

  getAllSurveys() {
    //this.globals.isLoading = true;
    this.survey.getAllSurveys().then(
      data => {
        this.allsurveys = data;
        // this.allsurveys.splice(this.allsurveys.findIndex(i => i.SurveyId == 11), 4);
        for (let i = 0; i < this.allsurveys.length; i++) {
          if (new Date(this.allsurveys[i].ExpiryDate) < this.usaTime) {
            this.allsurveys[i]['status'] = 'Closed';
          } else {
            this.allsurveys[i]['status'] = 'Active';
          }
        }        
        this.surveyLoadItems();
        this.globals.isLoading = false;
      },
      error => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
          showConfirmButton: false,
          timer: 4000
        })
      });
  }

  public userPageChange(event: PageChangeEvent): void { // change page
    this.state.skip = event.skip;
    this.state.take = event.take;
    this.surveyLoadItems();
  }

  private surveyLoadItems(): void { // load data bases on page
    this.view = {
      data: this.allsurveys.slice(this.state.skip, this.state.skip + this.state.take),
      total: this.allsurveys.length
    };
  }

  public addUpdate(e?: any) {
    if (e == null) {

      this.router.navigate(['AddSurvey']);
      //window.location.href = 'AddSurvey';
    } else {
      this.router.navigate(['AddSurvey' + '/' + this.allsurveys[e]['SurveyUUIID']]);
      // window.location.href = 'AddSurvey' + '/' + this.allsurveys[e]['SurveyUUIID'];
    }
  }

  public viewDetails(e) {
    this.router.navigate(['surveyQuestions' + '/' + this.allsurveys[e]['SurveyUUIID']]);
    //window.location.href = 'surveyQuestions' + '/' + this.allsurveys[e]['SurveyUUIID'];
  }

  public delete(e) {
    swal.fire({
      title: 'Delete',
      text: "Are you sure you want to Delete?",
      icon: 'warning',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        var data = {
          'SurveyId': this.allsurveys[e]['SurveyId'],
          'AdminUserId': this.userId
        };
        this.survey.delete(JSON.stringify(data)).then(
          data => {
            if (data['message'] == 'Delete successfully') {
              this.allsurveys = this.allsurveys.filter(array => array.SurveyId != this.allsurveys[e]['SurveyId']);
              this.surveyLoadItems();
              this.overview.emit();
              this.globals.isLoading = false;
              swal({
                type: 'success',
                title: 'success',
                text: 'Record Deleted successfully.',
                showConfirmButton: false,
                timer: 4000
              })
            }
          },
          error => {
            this.getAllSurveys();
            this.globals.isLoading = false;
            swal({
              type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
              title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
              text: error.error.message,
              showConfirmButton: false,
              timer: 4000
            })
          });
      }
    })
  }
}
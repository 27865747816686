import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SurveyService } from "src/app/services/survey.service";
import { Globals } from "../../globals";
declare var $: any;

@Component({
  selector: "app-thankyou",
  templateUrl: "./thankyou.component.html",
  styleUrls: ["./thankyou.component.css"],
})
export class ThankyouComponent implements OnInit {
  aerexperts: boolean = false;
  theopeneyes: boolean = false;
  examity: boolean = false;
  surpass: boolean = false;
  getyardstick: boolean = false;
  prolydian: boolean = false;
  trueability: boolean = false;
  ata: boolean = false;
  kryteriononline: boolean = false;
  atp: boolean = false;
  amsn: boolean = false;
  connection: boolean = false;
  ThankyouText: any = null;
  RedirectionURL: any = null;

  constructor(private SurveyService: SurveyService, public globals: Globals, private router: Router) {}

  ngOnInit() {
    this.SurveyService.getThankyouText(this.globals.surveyId).then((data) => {
      this.globals.isLoading = true;
      if (data["message"] != "Something is wrong!") {
        console.log(data);
        data["ThankyouText"] ? (this.ThankyouText = data["ThankyouText"]) : (this.ThankyouText = "");
        data["RedirectionURL"] ? (this.RedirectionURL = data["RedirectionURL"]) : (this.RedirectionURL = "");
      }
      this.globals.isLoading = false;
    });

    if (this.globals.surveyId == 1) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = true;
      this.surpass = true;
      this.getyardstick = true;
      this.prolydian = true;
      this.trueability = true;
      this.ata = true;
      this.kryteriononline = true;
    } else if (this.globals.surveyId == 2) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = true;
      this.surpass = true;
      this.getyardstick = true;
      this.prolydian = true;
      this.trueability = true;
      this.ata = true;
      this.kryteriononline = true;
    } else if (this.globals.surveyId == 3) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = true;
      this.surpass = true;
      this.getyardstick = true;
      this.prolydian = true;
      this.trueability = true;
      this.ata = true;
      this.kryteriononline = true;
    } else if (this.globals.surveyId == 4) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = true;
      this.surpass = true;
      this.getyardstick = true;
      this.prolydian = true;
      this.trueability = true;
      this.ata = true;
      this.kryteriononline = true;
    } else if (this.globals.surveyId == 5) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = true;
      this.surpass = true;
      this.getyardstick = true;
      this.prolydian = true;
      this.trueability = true;
      this.ata = true;
      this.kryteriononline = true;
    } else if (this.globals.surveyId == 6) {
      this.aerexperts = true;
      this.theopeneyes = true;
      this.examity = true;
      this.surpass = true;
      this.getyardstick = true;
      this.prolydian = true;
      this.trueability = true;
      this.ata = true;
      this.kryteriononline = true;
    } else if (this.globals.surveyId == 7) {
      this.aerexperts = true;
      this.theopeneyes = true;
    } else if (this.globals.surveyId == 8) {
      this.theopeneyes = true;
      this.atp = true;
    } else if (this.globals.surveyId == 9) {
      this.theopeneyes = true;
      this.atp = true;
    } else if (this.globals.surveyId == 10) {
      this.theopeneyes = true;
      this.atp = true;
    } else if (this.globals.surveyId == 11) {
      this.theopeneyes = true;
      this.atp = true;
    } else if (this.globals.surveyId == 13) {
      this.amsn = true;
    } else if (this.globals.surveyId == 14) {
      this.amsn = true;
    } else if (this.globals.surveyId == 17) {
      this.connection = true;
    }

    // $(window).scrollTop(0);
    // setTimeout(() => {
    //   if (this.atp) {
    //     window.location.href = "/home";
    //     //window.location.href = 'https://www.testpublishers.org/';
    //   } else if (this.amsn) {
    //     window.location.href = "https://amsn.org/events/calendar";
    //   } else if (this.RedirectionURL) {
    //     window.location.href = this.RedirectionURL;
    //   } else {
    //     this.router.navigate(["/home"]);
    //   }
    // }, 5000);
  }
}

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Globals } from ".././globals";
import { HttpClient } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs/Rx";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router,
    private cookieService: CookieService
  ) {}
  public login$: Observable<any>;

  login(loginData) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + "Survey/Auth/login", loginData)
        //this.http.post(this.globals.baseAPIUrl + 'Survey/login', loginData)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  isAdmin() {
    var lastIndex = window.location.href.substring(window.location.href.lastIndexOf("/"));
    var secondLastIndex = window.location.href.substring(
      window.location.href.lastIndexOf("/", window.location.href.lastIndexOf("/") - 1),
      window.location.href.lastIndexOf("/")
    );
    return lastIndex == "/login" ||
      lastIndex == "/dashboard" ||
      lastIndex == "/administrator" ||
      secondLastIndex == "/surveyQuestions" ||
      secondLastIndex == "/AddSurvey"
      ? true
      : false;
  }

  // isLoggedIn() {
  //   if (localStorage.getItem('userLogin')) {
  //     var userLogin = JSON.parse(localStorage.getItem('userLogin'));

  //     var now = formatDate(new Date(), 'yyyy/MM/dd, h:mm:ss a', 'en_US');
  //     var expiry = formatDate(userLogin.expiry, 'yyyy/MM/dd, h:mm:ss a', 'en_US');

  //     //console.log("now " + now);
  //     //console.log("expiry " + expiry);

  //     if (expiry < now) {
  //       this.globals.userLogin = false;
  //     //this.globals.authData = '';
  //     localStorage.removeItem('token');
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     this.globals.userLogin = false;
  //     return false;
  //   }
  // }
  isLoggedIn() {
    let jwtHelper = new JwtHelperService();
    let token = this.cookieService.get("token");
    let isExpired = jwtHelper.isTokenExpired(token) ? true : false;
    return !isExpired;
  }

  logout() {
    this.cookieService.delete("token", "/", this.globals.CookieDomainUrl);
    this.cookieService.delete("Role", "/", this.globals.CookieDomainUrl);
    this.cookieService.deleteAll("/", "token");
    this.cookieService.deleteAll("/", "Role");

    this.globals.userLogin = false;
    //window.location.href = '/home';
    this.router.navigate(["login"]);
    /* 	let promise = new Promise((resolve, reject) => {
        this.http.get(this.globals.baseAPIUrl + 'Login/logout/' + UserId)
          .toPromise()
          .then(
            res => {
              //this.globals.authData = '';
              localStorage.removeItem('token');
              localStorage.removeItem('getUserPermission');
              resolve(res);
            },
            msg => { // Error
              reject(msg);
              this.globals.isLoading = false;
              this.router.navigate(['/pagenotfound']);
            }
          );
      }); */
    //	return promise;
  }

  /* logout(logoutEntity) {
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'Survey/Auth/logout', logoutEntity)
        .toPromise()
        .then(
          res => { // Success
            //this.globals.authData = '';
            localStorage.removeItem('token');
            resolve(res);
          },
          msg => { // Error
            reject(msg);
            // this.router.navigate(['/pagenotfound']);
          }
        );
    });
    return promise;
  } */
}

import { Component, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { Globals } from "../globals";
import { AuthService } from "../services/auth.service";
/* import { disableBindings } from '@angular/core/src/render3'; 
import { JwtHelperService } from '@auth0/angular-jwt';*/
declare var $: any, swal;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  logoutbtn: boolean = false;
  amsnLogo: boolean = false;
  connektion: boolean = false;

  constructor(private router: Router, public globals: Globals, public auth: AuthService) {
    var URL = window.location.href.substring(window.location.href.lastIndexOf("/"));
    URL == "/dashboard" ? (this.logoutbtn = true) : (this.logoutbtn = false);

    if (URL == "/amsn7460-57d7-11er-8217-7ddc5e4b89b4" || URL == "/amsn2260-57d7-11er-8217-7ddc5e4b89b5") {
      this.amsnLogo = true;
    }
    if (URL == "/fcd8dd36-57a7-448b-b310-3a60794d71fd") {
      this.connektion = true;
    }
  }

  ngOnInit() {
    setTimeout(() => {
      const body = document.querySelector("body");
      body.style.setProperty("--screen-height", $(window).height() + "px");
    }, 100);
  }
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { SurveyService } from "../../services/survey.service";
import { Globals } from "../../globals";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from "ngx-cookie-service";
declare var $: any, swal;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  constructor(
    private globals: Globals,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private SurveyService: SurveyService,
    private router: Router,
    private cookieService: CookieService
  ) {
    if (this.auth.isLoggedIn()) {
      router.navigate(["dashboard"]);
    }
  }

  loginForm: FormGroup;
  errorMsg: boolean = false;
  public now: Date = new Date();

  ngOnInit() {
    $("#password-show").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      if ($("#password").attr("type") == "password") {
        $("#password").attr("type", "text");
      } else {
        $("#password").attr("type", "password");
      }
    });

    this.loginForm = this.formBuilder.group({
      EmailAddress: [
        "",
        [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],
      ],
      Password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  login(loginForm: FormGroup) {
    this.globals.isLoading = true;
    this.auth.login(this.loginForm.value).then(
      (data) => {
        if (data["status"] == "Successful login") {
          this.errorMsg = false;
          let jwtHelper = new JwtHelperService();
          const decodedToken = jwtHelper.decodeToken(data["token"]);
          // localStorage.setItem("token", data["token"]);
          // localStorage.setItem("Role", decodedToken.Role);n
          this.cookieService.set("token", data["token"], 1, "/", this.globals.CookieDomainUrl);
          this.cookieService.set("Role", decodedToken.Role, 1, "/", this.globals.CookieDomainUrl);
          //console.log(localStorage.getItem('token'));
          //localstoreage expiry time start
          /*  var expiry = new Date();
           expiry.setMinutes(expiry.getMinutes() + 30);
           const item = {
             survey: window.btoa(data['message']),
             start: new Date(),
             expiry: expiry
           }
           //localstoreage expiry time end
           localStorage.setItem('userLogin', JSON.stringify(item));
           //window.location.href = '/dashboard';
           */
          decodedToken.Role == 1 ? this.router.navigate(["administrator"]) : this.router.navigate(["dashboard"]);
        } else {
          this.globals.isLoading = false;
          this.errorMsg = true;
        }
      },
      (error) => {
        this.globals.isLoading = false;
        swal({
          type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
          showConfirmButton: false,
          timer: 4000,
        });
      }
    );
  }
}

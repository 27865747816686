import { Component, OnInit, ViewChild } from "@angular/core";
import { Globals } from "../../globals";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { SurveyService } from "../../services/survey.service";
import { DomSanitizer } from "@angular/platform-browser";
declare var $, swal: any, Bloodhound: any;

@Component({
  selector: "app-introduction",
  templateUrl: "./introduction.component.html",
  styleUrls: ["./introduction.component.css"],
})
export class IntroductionComponent implements OnInit {
  id;
  surveyDetails: any = {};
  slider1: boolean = true;
  slider2: boolean = false;
  answer: string;
  QuestionId: string;
  surveyId: number;
  AnswerText: string;
  surveyUUID: string;

  constructor(
    private sanitizer: DomSanitizer,
    public globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private SurveyService: SurveyService
  ) {}

  ngOnInit() {
    $(window).scrollTop(0);
    setTimeout(() => {
      const body = document.querySelector("body");
      body.style.setProperty("--screen-height", $(window).height() + "px");
    }, 100);

    this.id = this.route.snapshot.paramMap.get("id");
    this.SurveyService.getSurveyDetails(this.id).then(
      (data) => {
        this.globals.isLoading = true;
        this.surveyDetails = data;
        this.surveyDetails.IntroductionText = this.sanitizer.bypassSecurityTrustHtml(
          this.surveyDetails.IntroductionText
        );
        this.globals.logo = data["Logo"];
        this.globals.logoURL = data["LogoURL"];
        if (new Date(this.surveyDetails.ExpiryDate) >= new Date(this.globals.usaTime)) {
        } else {
          this.globals.surveyAbbreviation(this.id);
          this.router.navigate(["/pagenotfound"]);
        }
        this.globals.isLoading = false;
      },
      (error) => {
        this.globals.isLoading = false;
        this.router.navigate(["/pagenotfound"]);
      }
    );
  }

  goToSurvey() {
    this.globals.isLoading = true;
    if (this.id == "atp57460-57d7-11ea-8217-7ddc5e4b89b3") {
      this.slider1 = false;
      this.slider2 = true;
      this.globals.isLoading = false;
    } else {
      let postdata = { SurveyId: this.globals.surveyAbbreviation(this.id).id };
      this.SurveyService.startSurvey(postdata).then(
        (data) => {
          this.globals.isLoading = false;
          if (this.id === "859becf9-7e4a-419f-8543-c13e07244d3f") {
            this.router.navigate([
              "personal/" +
                this.globals.surveyAbbreviation(this.id).name +
                "/" +
                this.id +
                "/" +
                data["UserId"] +
                "/" +
                data["SurveyUserId"],
            ]);
          } else if (this.id === "9de67f7e-a3e5-4b3e-ba97-0ccbe1e16d4b") {
            this.router.navigate([
              "personal/" +
                this.globals.surveyAbbreviation(this.id).name +
                "/" +
                this.id +
                "/" +
                data["UserId"] +
                "/" +
                data["SurveyUserId"],
            ]);
          } else {
            this.router.navigate([
              this.globals.surveyAbbreviation(this.id).name +
                "/survey/" +
                this.id +
                "/" +
                data["UserId"] +
                "/" +
                data["SurveyUserId"],
            ]);
          }
        },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      );
    }
  }

  submitAnswer(QuestionId, AnswerText, Comment) {
    //this.globals.isLoading = true;
    if (AnswerText == "Employer") {
      this.surveyId = 8;
      this.surveyUUID = "wfs87460-57d7-11ea-8217-7ddc5e4b89b3";
    } else if (AnswerText == "Employee/potential employee") {
      this.surveyId = 9;
      this.surveyUUID = "atp87460-57d7-11ea-8217-7ddc5e4b89b3";
    } else if (AnswerText == "Credential Issuer or Badge Issuer") {
      this.surveyId = 10;
      this.surveyUUID = "atp37460-57d7-11ea-8217-7ddc5e4b89b3";
    } else if (AnswerText == "Test Publisher") {
      this.surveyId = 11;
      this.surveyUUID = "atp47460-57d7-11ea-8217-7ddc5e4b89b3";
    }

    this.QuestionId = QuestionId;
    this.AnswerText = AnswerText;
  }

  next() {
    this.globals.isLoading = true;
    if (this.surveyId == null && this.surveyUUID == null) {
      this.globals.isLoading = false;
      swal({
        type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
        title: "Error",
        text: "Please choose any option.",
        showConfirmButton: false,
        timer: 4000,
      });
    } else {
      this.SurveyService.startSurvey({ SurveyId: this.surveyId }).then(
        (data) => {
          let postData = {
            SurveyUserId: data["SurveyUserId"],
            SurveyAnswerId: null,
            QuestionId: this.QuestionId,
            AnswerText: this.AnswerText,
            Comment: Comment,
          };

          this.SurveyService.submitAnswer(postData).then(
            (data) => {
              // this.questionList[index].SurveyAnswerId = data['SurveyAnswerId'];
              // this.questionList[index].AnswerText = $.trim(AnswerText);
              // this.questionList[index].Comment = Comment;
              this.globals.isLoading = false;
            },
            (error) => {
              this.globals.isLoading = false;
              swal({
                type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
                title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
                text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
                showConfirmButton: false,
                timer: 4000,
              });
            }
          );

          //console.log(['ATP/survey/' + this.surveyUUID + '/' + data['UserId'] + '/' + data['SurveyUserId']]);
          this.router.navigate(["ATP/survey/" + this.surveyUUID + "/" + data["UserId"] + "/" + data["SurveyUserId"]]);
        },
        (error) => {
          this.globals.isLoading = false;
          swal({
            type: this.globals.commonTranslationText.common.alerts.somethingWrong.type,
            title: this.globals.commonTranslationText.common.alerts.somethingWrong.title,
            text: this.globals.commonTranslationText.common.alerts.somethingWrong.text,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      );
    }
  }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { Globals } from '../../../globals';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  @Input('surveyOverviewData') surveyOverviewData;

  surveyOverview;
  public overviewGridView: GridDataResult;
  public overviewPageSizes: any = [10, 25, 50, 100, {
    text: 'All',
    value: 'all'
  }];
  public overviewPageSize = 10;
  public overviewSkip = 0;

  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    this.surveyOverview = this.surveyOverviewData;
    //update date from string to date time object
    /*   for (var v in this.surveyOverview) {
        this.surveyOverview[v]['date'] = new Date(this.surveyOverview[v]['date']);
      } */
    // for (var v in this.surveyOverview) {
    //   this.surveyOverview[v]['date'] =formatDate(this.surveyOverview[v]['date'], 'MM/dd/yyyy', 'en_US');
    // }
    this.overviewLoadItems();
  }

  public overviewPageChange(event: PageChangeEvent): void { // change page
    this.overviewSkip = event.skip;
    this.overviewPageSize = event.take;
    this.overviewLoadItems();
    this.overviewLoadProducts();
  }

  private overviewLoadItems(): void { // load data bases on page
    //this.globals.isLoading = true;
    this.overviewGridView = {
      data: this.surveyOverview.slice(this.overviewSkip, this.overviewSkip + this.overviewPageSize),
      total: this.surveyOverview.length
    };
    //this.globals.isLoading = false;
  }

  private overviewLoadProducts(): void { // create groups
    this.overviewGridView = process(this.overviewGridView.data, { sort: this.overviewSort });
    this.overviewGridView.total = this.surveyOverview.length;
  }

  public overviewSortChange(sort: SortDescriptor[]): void {
    this.overviewSort = sort;
    this.overviewGridView = process(this.surveyOverview, { sort: this.overviewSort });
    this.overviewGridView.total = this.surveyOverview.length;
    this.surveyOverview = this.overviewGridView.data;
    this.surveyOverview.total = this.surveyOverview.length;
    //this.loadItems();
    this.overviewGridView = {
      data: this.overviewGridView.data.slice(this.overviewSkip, this.overviewSkip + this.overviewPageSize),
      total: this.overviewGridView.total
    };
    this.overviewLoadProducts();
  }

  public overviewSort: SortDescriptor[] = [{
    field: 'date',
    dir: 'desc'
  }];
}

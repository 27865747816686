import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "../environments/environment";
import commonTranslationText from "../assets/data/commonTranslationText.json";
import { Title } from "@angular/platform-browser";

@Injectable()
export class Globals {
  constructor(public title: Title) {
    this.title.setTitle("OpenEyes Surveys");
  }

  baseAPIUrl: string = environment.apiUrl;
  baseUrl: string = environment.baseUrl;
  CookieDomainUrl: string = environment.CookieDomainUrl;
  analyticsUrl: string = environment.analyticsUrl;
  headerpath: string = "{'Content-Type': 'application/json','Accept': 'application/json'}";
  IsLoggedIn: boolean = false;
  isLoading: boolean = false;
  LoadingText: string = "Loading...";
  currentLink: string = "";
  currentModule: string = "";
  check_login = false;
  todaysdate: string = "";
  WebsiteUrl = "oess";
  WebsiteName: string = "OpenEyes Technologies Inc.";
  commonTranslationText = commonTranslationText;
  logo: string = "";
  logoURL: string = "";
  surveyId: number;
  userLogin: boolean = false;
  usaTime = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York",
  });

  surveyAbbreviation(uuid) {
    if (uuid == "2c74d40d-84ba-4d51-b98f-0355f190914c") {
      this.surveyId = 1;
      return { name: "VCRP", id: 1 };
    } else if (uuid == "43fc26ec-4bd7-447b-bd4b-caf0a3c25f18") {
      this.surveyId = 2;
      return { name: "VCRP", id: 2 };
    } else if (uuid == "54618d00-56ec-11ea-abd5-6f99e4f3bff5") {
      this.surveyId = 3;
      return { name: "VCRP", id: 3 };
    } else if (uuid == "297383f0-57d7-11ea-9fc4-0ddbffad35aa") {
      this.surveyId = 4;
      return { name: "VCRP", id: 4 };
    } else if (uuid == "26568e14-e621-4265-8683-f8beb75f4850") {
      this.surveyId = 5;
      return { name: "VCRP", id: 5 };
    } else if (uuid == "ccf87460-57d7-11ea-8217-7ddc5e4b89b3") {
      this.surveyId = 6;
      return { name: "VCRP", id: 6 };
    } else if (uuid == "rdf87460-57d7-11ea-8217-7ddc5e4b89b3") {
      this.surveyId = 7;
      return { name: "YIR", id: 7 };
    } else if (uuid == "wfs87460-57d7-11ea-8217-7ddc5e4b89b3") {
      this.surveyId = 8;
      return { name: "ATP", id: 8 };
    } else if (uuid == "atp87460-57d7-11ea-8217-7ddc5e4b89b3") {
      this.surveyId = 9;
      return { name: "ATP", id: 9 };
    } else if (uuid == "atp37460-57d7-11ea-8217-7ddc5e4b89b3") {
      this.surveyId = 10;
      return { name: "ATP", id: 10 };
    } else if (uuid == "atp47460-57d7-11ea-8217-7ddc5e4b89b3") {
      this.surveyId = 11;
      return { name: "ATP", id: 11 };
    } else if (uuid == "atp57460-57d7-11ea-8217-7ddc5e4b89b3") {
      this.surveyId = 12;
      return { name: "ATP", id: 12 };
    } else if (uuid == "amsn7460-57d7-11er-8217-7ddc5e4b89b4") {
      this.surveyId = 13;
      return { name: "AMSN", id: 13 };
    } else if (uuid == "amsn2260-57d7-11er-8217-7ddc5e4b89b5") {
      this.surveyId = 14;
      return { name: "AMSN", id: 14 };
    } else if (uuid == "6acf092a-b744-4e7c-bc9f-f1d4fa27b291") {
      this.surveyId = 15;
      return { name: "DIY", id: 15 };
    } else if (uuid == "fcd8dd36-57a7-448b-b310-3a60794d71fd") {
      this.surveyId = 16;
      return { name: "connektion-student", id: 16 };
    } else if (uuid == "859becf9-7e4a-419f-8543-c13e07244d3f") {
      this.surveyId = 17;
      return { name: "hrci-reunion-it-2021", id: 17 };
    } else if (uuid == "9de67f7e-a3e5-4b3e-ba97-0ccbe1e16d4b") {
      this.surveyId = 18;
      return { name: "OESS-Diwali-2021", id: 18 };
    }
  }
}

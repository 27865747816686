import { Component, OnInit } from '@angular/core';
import { Globals } from './../globals';
declare var $, swal: any, Bloodhound: any, PerfectScrollbar;

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit() {
    $(window).scrollTop(0);
  }


}
